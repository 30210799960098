import { Box, createTheme, Grid, TextField, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import BusinessIcon from "@mui/icons-material/Business";
import BadgeIcon from "@mui/icons-material/Badge";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import ImageIcon from "@mui/icons-material/Image";
import { Modal } from 'react-bootstrap';
import ReactPhoneInput from 'react-phone-input-material-ui';
import CNPJInput from '../Inputs/CNPJInput';
import { api } from '../../services/api';
import TextFieldInput from '../Inputs/TextFieldInput';
import PhoneInput from '../Inputs/PhoneInput';

export default function FormCompanie({id, dataCompanie, show, handleClose }) {
    const theme = createTheme({
        palette: {
          primary: {
            main: "#F85F2F",
            light: "#F85F2F",
            dark: "#F85F2F",
          },
        },
      });

      const formik = useFormik({
        initialValues: {
          name: '',
          fantasy_name: '',
          cnpj: '',
          owner: '',
          email: '',
          phone: '',
        }, 
        validationSchema: Yup.object({
          name: Yup.string().required('Campo Obrigatório'),
          fantasy_name: Yup.string().required('Campo Obrigatório'),
          cnpj: Yup.string().required('Campo Obrigatório'),
          owner: Yup.string().required('Campo Obrigatório'),
          email: !id && Yup.string().required('Campo Obrigatório'),
          phone: Yup.string().required('Campo Obrigatório'),
        }),
        onSubmit: (values) => {
          formik.setSubmitting(true);
          try {
            if (!id){
              api
              .post("/companies", values)
              .then((res) => {
                //const mydata = [...companies, res.data];
                //setCompanies(mydata);
                //setStatus("success");
                //setOpenSnackBar(true);
                handleClose(values);
              })
              .catch(
                (err) => {
                  console.error(err);
                  //setStatus("error");
                  //setOpenSnackBar(true);
                }
              );
              formik.setSubmitting(false);
            }else{
              api
          .put("/companies/" +id, values)
          .then((res) => {
            //setStatus("success");
            //setOpenSnackBar(true);
            //handleCloseEdit();
            handleClose(values);
          })
          .catch(
            (err) => {
              console.error(err);
              //setStatus("error");
              //setOpenSnackBar(true);
            }
          );
            formik.setSubmitting(false);
            }
          //request(values);
        } catch (err) {
          // error()
          console.log(err);
        }
        },
      });

    const [data, setData] = useState({
        name: "",
        fantasy_name: "",
        cnpj: "",
        owner: "",
        phone: "",
        email: "",
        password: "",
        logo: "",
      });
      function handle(e) {
        const newdata = { ...data };
        newdata[e.target.id] = e.target.value;
        setData(newdata);
      }

      function submit(e) {
        e.preventDefault();
        api
          .post("/companies", data)
          .then((res) => {
            //const mydata = [...companies, res.data];
            //setCompanies(mydata);
            //setStatus("success");
            //setOpenSnackBar(true);
            handleClose();
          })
          .catch(
            (err) => {
              console.error(err);
              //setStatus("error");
              //setOpenSnackBar(true);
            }
          );
      }
    
      function submitEdit(e) {
        e.preventDefault();
        /*api
          .put("/companies/" + data.id, data)
          .then((res) => {
            //setStatus("success");
            //setOpenSnackBar(true);
            //handleCloseEdit();
          })
          .catch(
            (err) => {
              console.error(err);
              //setStatus("error");
              //setOpenSnackBar(true);
            }
          );*/
      }

      useEffect(() => {
        if(!id){
          formik.resetForm();
        }
      }, [show]);
      
      useEffect(() => {
        if(id){
          formik.resetForm();
          formik.setValues(dataCompanie);
        }
      }, [id])

      const clearModal = () => {

        handleClose(!show)
      }
  return (
    <Box flex={6} p={2}>
      <br />
      <h2 className="laranja">Grupos Salineiros</h2>
  
      <Modal show={show} onHide={() => clearModal()}>
        <Modal.Header closeButton>
          <Modal.Title className="laranja">{id? 'Editar Grupo Salineiro' : 'Adicionar Grupo Salineiro'}</Modal.Title>
        </Modal.Header>
        <div className="container">
          <br />
          <form onSubmit={formik.handleSubmit}>
            <ThemeProvider theme={theme}>
              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid paddingTop={1}>
                  <AccountBoxIcon style={{ fontSize: "45px" }} color="primary" />
                  &nbsp; &nbsp;
                </Grid>
                <Grid style={{width: "40%"}}>
                <TextFieldInput autoFocus id="name" label="Nome da Salina" name="name" formik={formik} />                  
                </Grid>
              </Grid>

              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid paddingTop={1}>
                  <BusinessIcon style={{ fontSize: "45px" }} color="primary" />
                  &nbsp; &nbsp;
                </Grid>
                <Grid style={{width: "40%"}}>
                <TextFieldInput id="fantasy_name" label="Nome Fantasia" name="fantasy_name" formik={formik} />
                </Grid>
              </Grid>

              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid paddingTop={1}>
                  <BadgeIcon style={{ fontSize: "45px" }} color="primary" />
                  &nbsp; &nbsp;
                </Grid>
                <Grid style={{width: "40%"}}>
                <CNPJInput id="cnpj" label="CNPJ" name="cnpj" formik={formik} />                          
                </Grid>
              </Grid>

              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid paddingTop={1}>
                  <CoPresentIcon style={{ fontSize: "45px" }} color="primary" />
                  &nbsp; &nbsp;
                </Grid>
                <Grid style={{width: "40%"}}>
                <TextFieldInput id="owner" label="Proprietario" name="owner" formik={formik} />
                </Grid>
              </Grid>

              <Grid container direction="row" alignItems="center" justifyContent="center" style={{border: 'none'}}>
                <Grid paddingTop={1}>
                  <PhoneIcon style={{ fontSize: "45px" }} color="primary" />
                  &nbsp; &nbsp;
                </Grid>
                <Grid style={{width: "40%"}}>
                  <PhoneInput id="phone" label="Telefone" name="phone" formik={formik} />                   
                </Grid>
              </Grid>

              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid paddingTop={1}>
                  <EmailIcon style={{ fontSize: "45px" }} color="primary" />
                  &nbsp; &nbsp;
                </Grid>
                <Grid style={{width: "40%"}}>
                <TextFieldInput id="email" label="Email" name="email" type="email" formik={formik} />
                </Grid>
              </Grid>

              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid paddingTop={1}>
                  <LockIcon style={{ fontSize: "45px" }} color="primary" />
                  &nbsp; &nbsp;
                </Grid>
                <Grid style={{width: "40%"}}>
                <TextFieldInput  
                  id="password"
                  label="Senha"
                  type="text"
                  name="password"
                  required = {id || false}                  
                  formik={formik}
                />    
                </Grid>
              </Grid>

              {/*<Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid paddingTop={1}>
                  <ImageIcon style={{ fontSize: "45px" }} color="primary" />
                  &nbsp; &nbsp;
                </Grid>
                <Grid style={{width: "40%"}}>
                  <TextField
                  style={{border: "none"}}
                    label="Logo"
                    InputLabelProps={{ required: false }}
                    onChange={(e) => handle(e)}
                    type="file"
                    name="logo"
                    id="logo"
                    className="form-control"
                    variant="standard"
                  />
                </Grid>
              </Grid>*/}
              <br />
            </ThemeProvider>

            <Modal.Footer>
            <button
                className="btn"
                style={{
                  backgroundColor: "#F85F2F",
                  color: "white",
                }}
              >
                {id ? 'Editar': 'Adicionar'}
              </button>
            </Modal.Footer>
          </form>
        </div>
      </Modal>
      </Box>
  )
}
