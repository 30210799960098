import React from "react";
import DefaultNavBar from "../../components/DefaultNavBar";
import Weather from "../../components/Weather";
import Companies from "../../components/Companies/Companies";
import { Box, Stack } from "@mui/material";
import "bootstrap/dist/css/bootstrap.css";
import "../../styles/home.css";

const HomePage = () => {
  return (
    <Box>
      <DefaultNavBar />
      <Stack direction="row" spacing={1} justify="space-between">
        <Weather />
        <Companies />
      </Stack>
    </Box>
  );
};

export default HomePage;
