import React, { useState } from "react";

import { Box, Fab, Tooltip } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";

import Modal from "react-bootstrap/Modal";
import Multiselect from "multiselect-react-dropdown";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { CSVDownload } from "react-csv";

import { api } from "../../services/api";
import { downloadCSV } from "../../services/measurements";
import smartsaltLogo from "../../images/resizedLogo.png";

import moment from "moment";
import "moment/locale/pt-br";
import SnackbarComponent from "../GeneralElements/Snackbar";

const ExportData = (props) => {
  let measurements = [];
  let saltern;
  let selectedCategory;

  const station = props.station;
  const salternId = props.salternId;
  const properties = props.properties;
  const categories = props.categories;

  const [status, setStatus] = useState();
  const [message, setMessage] = useState(undefined);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [formato, setFormato] = useState(undefined);
  const [dataInicial, setDataInicial] = useState(undefined);
  const [dataFinal, setDataFinal] = useState(undefined);
  const [handleCSVDoneload, setHandleCSVDoneload] = useState(<div></div>);

  const [options] = useState(properties);
  const [selectedOptions, setSelectedOptions] = useState(undefined);

  const handleChange = (options) => {
    setSelectedOptions(options);
  };

  const handleCloseSnackBar = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  async function getMeasurements() {
    await api
      .get(`/measurements?station_id=${station.id}`)
      .then((res) => {
        measurements = res.data;
      })
      .catch((err) => console.error(err));
  }

  async function searchSaltern() {
    await api
      .get(`/salterns/${salternId}`)
      .then((res) => {
        saltern = res.data;
      })
      .catch((err) => console.error(err));
  }

  async function getCategory() {
    await categories
      ?.filter((category) => category.id === station.category_id)
      .map((category) => {
        return selectedCategory = category.name
      })
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: "#F85F2F",
        light: "#F85F2F",
        dark: "#F85F2F",
      },
    },
  });

  async function handle() {
    if(formato && dataInicial && dataFinal && selectedOptions) {
    if (formato === "pdf") {
      await getMeasurements();
      await searchSaltern();
      await getCategory();
      exportData(measurements);
    } else if (formato === "csv") {
      const csvData = await downloadCSV(station.id, dataInicial, dataFinal, selectedOptions);
      setHandleCSVDoneload(<CSVDownload data={csvData} target="_blank" />);
      setTimeout(() => {
        setHandleCSVDoneload(<></>);
      }, 1000);
    }
  }else{
    alert("Por favor, selecione todos os campos necessarios...")
    setMessage("Selecione todos os campos necessarios...");
    setStatus("success");
    setOpenSnackBar(true);
  }
  }

  function exportData(data) {

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    function dados(element) { return data
      .sort((a, b) => new Date(a.collected_start) - new Date(b.collected_start))
      .filter((item) => item.property_id === element.id &&
        item.collected_start >= (
          ( moment(dataInicial).format("YYYY-MM-DD") ) + "T00:00:00.000Z"
        ) &&
        item.collected_start <= (
          ( moment(dataFinal).format("YYYY-MM-DD") ) + "T00:00:00.000Z"
        )
      )
      .map((item) => {
        moment.locale("pt-br");
        return [
          { text: item.value, fontSize: 12, margin: [0, 2, 0, 2] },
          {
            text: moment(item.collected_start).format("L"),
            fontSize: 12,
            margin: [0, 2, 0, 2],
          },
        ];
      });
    }

    let dados2 = data
      .sort((a, b) => new Date(a.collected_start) - new Date(b.collected_start))
      .filter((item) => item.property_id === 2 &&
        item.collected_start >= (
          ( moment(dataInicial).format("YYYY-MM-DD") ) + "T00:00:00.000Z"
        ) &&
        item.collected_start <= (
          ( moment(dataFinal).format("YYYY-MM-DD") ) + "T00:00:00.000Z"
        )
      )
      .map((item) => {
        moment.locale("pt-br");
        return [
          { text: item.value, fontSize: 12, margin: [0, 2, 0, 2] },
          {
            text: moment(item.collected_start).format("L"),
            fontSize: 12,
            margin: [0, 2, 0, 2],
          },
        ];
      });

    let dados3 = data
      .sort((a, b) => new Date(a.collected_start) - new Date(b.collected_start))
      .filter((item) => item.property_id === 3 &&
        item.collected_start >= (
          ( moment(dataInicial).format("YYYY-MM-DD") ) + "T00:00:00.000Z"
        ) &&
        item.collected_start <= (
          ( moment(dataFinal).format("YYYY-MM-DD") ) + "T00:00:00.000Z"
        )
      )
      .map((item) => {
        moment.locale("pt-br");
        return [
          { text: item.value, fontSize: 12, margin: [0, 2, 0, 2] },
          {
            text: moment(item.collected_start).format("L"),
            fontSize: 12,
            margin: [0, 2, 0, 2],
          },
        ];
      });

    let details = [
      {
        columns: [
          {
            stack: [
              {
                text: "Histórico de Mensurações (" 
                  + moment(dataInicial).format("L") 
                  + ") - (" 
                  + moment(dataFinal).format("L") 
                  + ")",
                fontSize: 16,
                bold: true,
              },
              {
                text: "Salina: " + saltern.name,
                fontSize: 12,
              },
              {
                text: "Endereço: "
                  + (saltern.street === null || saltern.street === "" ? "" : saltern.street + ", ")
                  + (saltern.number === null || saltern.number === "" ? "" : saltern.number + ", ") 
                  + (saltern.neighborhood === null || saltern.neighborhood === "" ? "" : saltern.neighborhood + ", ")
                  + (saltern.city === null || saltern.city === "" ? "" : saltern.city)
                  + (saltern.state === null || saltern.state === "" ? "" : "/" + saltern.state),
                fontSize: 12,
              },
              {
                text: "E-mail: " + saltern.email + "\tTelefone: " + saltern.phone,
                fontSize: 12,
              },
            ],
            width: '75%',
          },
          {
            image: smartsaltLogo,
            width: 115,
            height: 30,
            margin: [0, 15, 0, 0],
          },
        ]
      },
      {
        text: "Estação: " + station.name,
        fontSize: 14,
        bold: true,
        margin: [0, 20, 0, 0],
      },
      {
        text: "Área: " + station.area + " km²",
        fontSize: 12,
      },
      {
        text: "Tipo: " + selectedCategory,
        fontSize: 12,
        margin: [0, 0, 0, 15],
      },
    ];

    selectedOptions.forEach((element) => {
        details.push({ text: ""+element.name, margin: [15, 10, 0, 8], bold: true });
        details.push({
          table: {
            headerRows: 1,
            widths: ["*", "*"],
            body: [
              [
                {
                  text: `Valor (${element.unit})`,
                  style: "tableHeader",
                  fontSize: 12,
                },
                {
                  text: "Data da Coleta",
                  style: "tableHeader",
                  fontSize: 12,
                },
              ],
              ...dados(element),
            ],
          },
          layout: "lightHorizontalLines",
        });
    });

    function Baseboard(currentPage, pageCount) {
      return [
        {
          text: currentPage + " / " + pageCount,
          alignment: "right",
          fontSize: 10,
          margin: [0, 10, 20, 0],
        },
      ];
    }

    const docDefinition = {
      pageSize: "A4",
      pageMargins: [15, 40, 15, 40],
      content: [details],
      footer: Baseboard,
    };

    pdfMake.createPdf(docDefinition).download();
  }

  return (
    <>
    <SnackbarComponent openSnackBar={openSnackBar} handleCloseSnackBar={handleCloseSnackBar} status={status} message={message}/>
    <Modal show={props.show} onHide={props.onHide} style={{ backgroundColor: "gray" }} size="md" autoFocus>
      <div className="container-fluid">
        <Modal.Header closeButton>
          <h2 style={{ fontWeight: "bold" }}>Exportar Dados</h2>
        </Modal.Header>
        {handleCSVDoneload}

        <div className="container-fluid">
          <br />
          <div className="container">
            <form>
              <div className="form-group">
                <h4 className="float-start laranja" htmlFor="formato">
                  Formato:
                </h4>
                <select
                  className="form-select"
                  id="formato"
                  value={formato}
                  onChange={(e) => setFormato(e.target.value)}
                >
                  <option className="text-center" value="">
                    --- Selecione o Formato de Exportação ---
                  </option>
                  <option value="pdf">PDF</option>
                  <option value="csv">CSV</option>
                </select>
              </div>
              <br />

              <div className="form-group">
                <label htmlFor="data_inicial">Data Inicial:</label>
                <input
                  onChange={(e) => setDataInicial(e.target.value)}
                  value={dataInicial}
                  type="date"
                  name="data_inicial"
                  id="data_inicial"
                  className="form-control"
                />
              </div>
              <br />

              <div className="form-group">
                <label htmlFor="data_final">Data Final:</label>
                <input
                  onChange={(e) => setDataFinal(e.target.value)}
                  value={dataFinal}
                  type="date"
                  name="data_final"
                  id="data_final"
                  className="form-control"
                />
              </div>
              <br />

              <div className="form-group">
                <label htmlFor="propriedades">Propriedades:</label>
                <Multiselect
                  id="options-multi"
                  options={options}
                  onSelect={handleChange}
                  displayValue="name"
                  placeholder="Selecionar..."
                  emptyRecordMsg="Não há mais nenhuma opção disponível."
                  style={{ chips: { background: "#F85F2F" } }}
                  avoidHighlightFirstOption="true"
                  showArrow="true"
                />
              </div>
              <br />

              <Box className="float-end">
                <Box onClick={handle} sx={{ "& > :not(style)": { m: 1 } }}>
                  <ThemeProvider theme={theme}>
                    <Tooltip title="Exportar Dados" color="primary">
                      <Fab theme={theme} color="primary" aria-label="add">
                        <CheckIcon />
                      </Fab>
                    </Tooltip>
                  </ThemeProvider>
                </Box>
              </Box>
            </form>
          </div>
          <br />
          <br />
          <br />
        </div>
        <br />
      </div>
    </Modal>

    </>
  );
};

export default ExportData;
