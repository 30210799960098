import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { api } from "../../services/api";
import { searchCompany } from "../../services/companies";
import { searchSaltern } from "../../services/salterns";
import TableComponent from "../Table/TableComponent";
import TableMembers from "./TableMembers";

const Members = (props) => {
  const idSaltern = props.idSaltern;
  const idCompany = props.idCompany;

  const user = JSON.parse(localStorage.getItem("user"));

  const [members, setMembers] = useState([]);
  const [company, setCompany] = useState();
  const [saltern, setSaltern] = useState();
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState({
    name: "",
    company_id: idCompany,
    saltern_id: idSaltern,
    email: "",
    password: "",
    phone: "",
  });

  useEffect(() => {
    api
      .get(`/employees?company_id=${idCompany}&saltern_id=${idSaltern}`)
      .then((res) => {
        setMembers(res.data.members);
      })
      .catch((err) => console.error(err));
  }, [data, idCompany, idSaltern]);

  useEffect(() => {
    (async () => {
      if (user["tipo"] === "admins") {
        const response = await searchCompany(idCompany);
        setCompany(response);
      } else {
        return null;
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCompany]);

  useEffect(() => {
    (async () => {
      const responseSaltern = await searchSaltern(idSaltern);
      setSaltern(responseSaltern);
      setLoading(false);
    })();
  }, [idSaltern]);

  return (
    <Box flex={6} p={2}>
      <br />
      <h2 className="laranja">Funcionários da Salina {saltern?.name}</h2>
     
      <TableMembers
        data={data}
        setData={setData}
        members={members}
        setMembers={setMembers}
        company={company}
        saltern={saltern}
        idCompany={idCompany}
        idSaltern={idSaltern}
        loading={loading}
      ></TableMembers>
    </Box>
  );
};

export default Members;
