import axios from "axios";

const weatherApi = axios.create({
  baseURL: "https://api.openweathermap.org/data/2.5/weather",
});

let apiKey = "df5f045cee54b131eb8ae912c11e5a54";

export async function getWeather(lat, lon) {
  return await weatherApi
    .get(`?lat=${lat}&lon=${lon}&units=metric&lang=pt_br&appid=${apiKey}`)
    .then((res) => {
      
      res = {
        wind: res.data.wind.speed,
        temp: res.data.main.temp,
        clouds: res.data.clouds.all,
      };

      return res;
    })
    .catch((err) => console.error(err));
}
