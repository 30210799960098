import { Alert, Autocomplete, createTheme, Grid, IconButton, Snackbar, TextField } from '@mui/material';
import { Box, ThemeProvider } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { FormFloating, Modal } from 'react-bootstrap';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import PlaceIcon from '@mui/icons-material/Place';
import NumbersIcon from '@mui/icons-material/Numbers';
import VillaIcon from '@mui/icons-material/Villa';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PinDropIcon from '@mui/icons-material/PinDrop';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import HvacIcon from '@mui/icons-material/Hvac';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import ReactPhoneInput from 'react-phone-input-material-ui';
import ReactInputMask from 'react-input-mask';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import Coordinates from "../Coordinates";
import TextFieldInput from "../Inputs/TextFieldInput";
import { api } from '../../services/api';
import CepInput from '../Inputs/CEPInput';
import PhoneInput from '../Inputs/PhoneInput';

export default function FormSaltern({theme, id, idCompany, dataSaltern, show, handleClose, callbackInsert, callbackEdit, setStatus, setOpenSnackBar }) {
    //Variaveis de Estado    
    const [showMap, setShowMap] = useState(false);
    const [state, setState] = useState({});
    const [city, setCity] = useState({});
    const [states, setStates] = useState([]);
    const [citys, setCitys] = useState([]);
    const [zipcode, setZipcode] = useState();
    const [salterns, setSalterns] = useState([]);
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [temporaryLatitude, setTemporaryLatitude] = useState("");
    const [temporaryLongitude, setTemporaryLongitude] = useState("");
    const [defaultCoordinates] = useState({latitude: -5.014045922918405, longitude: -37.17746300652319})
    const [data, setData] = useState({
      name: "",
      company_id: idCompany,
      street: "",
      city: "",
      zipcode: "",
      neighborhood: "",
      state: "",
      number: "",
      phone: "",
      email: "",
      latitude: "",
      longitude: "",
    });

const openMap = () => {
  setShowMap(true);
  setTemporaryLatitude(latitude);
  setTemporaryLongitude(longitude);
}

const closeMap = () => {
  setLatitude(temporaryLatitude);
  setLongitude(temporaryLongitude);
  setShowMap(false);
}

const submitMap = (e) => {
  e.preventDefault();
  formik.values.latitude = latitude;
  formik.values.longitude = longitude;
  setShowMap(false);
}

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log('O seu navegador não suporta Geolocalização.');
    }
  }
  
  function showPosition(position) {
    formik.values.latitude = position.coords.latitude;
    formik.values.longitude = position.coords.longitude;
    formik.setFieldValue('latitude', position.coords.latitude);
    formik.setFieldValue('longitude', position.coords.longitude);
    setLatitude( position.coords.latitude);
    setLongitude( position.coords.longitude)
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      street: '',
      state: '',
      city: '',
      neighborhood: '',
      zipcode: '',
      number: '',
      latitude: -17.1739245,
      longitude: -46.3263307,
      email: '',
      phone: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Campo Obrigatório'),
      street: Yup.string().required('Campo Obrigatório'),      
      neighborhood: Yup.string().required('Campo Obrigatório'),
      zipcode: Yup.string().required('Campo Obrigatório'),
      number: Yup.string().required('Campo Obrigatório'),
      latitude: Yup.number().required('Campo Obrigatório'),
      longitude: Yup.number().required('Campo Obrigatório'),
      email: Yup.string().required('Campo Obrigatório'),
      phone: Yup.string().required('Campo Obrigatório'),
    }),
    onSubmit: (values) => {
      formik.values.company_id = parseInt(idCompany);
      formik.values.state = state.sigla;
      formik.values.city = city.nome;
      //formik.values.zipcode = zipcode;
        if(id){
            //formik.values.latitude = latitude;
            //formik.values.longitude = longitude;
    api
      .put("/salterns/" + id, formik.values)
      .then((res) => {
        setStatus("success");
        setOpenSnackBar(true);
        callbackEdit(formik.values);
      })
      .catch(
        (err) => {
          console.error(err);
          setStatus("error");
          setOpenSnackBar(true);
          //handleClose();
        }
      );
        }else{
            //formik.values.latitude = latitude;
            //formik.values.longitude = longitude;
    api
      .post("/salterns", formik.values)
      .then((res) => {
        const mydata = [...salterns, res.data];
        setSalterns(mydata);
        setStatus("success");
        setOpenSnackBar(true);
        callbackInsert(res.data);
      })
      .catch(
        (err) => {
          console.error(err);
          setStatus("error");
          setOpenSnackBar(true);
        }
      );
        }
      //  request(values);
    },
  });

  useEffect(() => {
    api.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados').then((response) => {
      setStates(response.data);
    });
  }, []);

  useEffect(() => {
    if (id) {
      setState(states.find((item) => item.sigla === dataSaltern.state));
    }
  }, [id]);

  useEffect(() => {
    if(state.sigla){
      api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state.sigla}/distritos`)
      .then(response => {        
        setCitys(response.data);
        if(id){
          setCity(response.data.find(item => item.nome === dataSaltern.city))
        }
      });
    }
  }, [state]);

  useEffect(() => {
    if (formik.values.zipcode.length === 10 && !id) {
      const tempZipcode = formik.values.zipcode.replaceAll('.','').replaceAll('-','');
      api.get(`https://viacep.com.br/ws/${tempZipcode}/json/`).then((response) => {
        if(!response?.data.erro){
          formik.setFieldValue('street', response.data.logradouro);
          formik.setFieldValue('neighborhood', response.data.bairro);
          //formik.setFieldValue('city', response.data.localidade);
          setState(states.find((item) => item.sigla === response.data.uf));
          const tempLocalidade = response.data.localidade;
          api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${response.data.uf}/distritos`)
          .then(response => {        
            setCitys(response.data);

            setCity(response.data.find(item => item.nome === tempLocalidade))          
          });
          //formik.setFieldValue('state', response.data.uf);
      }
      });
    }
  }, [formik.values.zipcode]);
 
  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    if(!id){
      formik.resetForm();
    }
  }, [show]);

  useEffect(() => {
    if(id){
      formik.resetForm();     
      formik.setValues(dataSaltern);
      
    }
  }, [id])
  
  return (
    <Box flex={6} p={2}> 

    <Modal show={show} onHide={() => handleClose(!show)}>
      <Modal.Header closeButton>
        <Modal.Title className="laranja">{id? 'Editar Salina' : 'Adicionar Salina'}</Modal.Title>
      </Modal.Header>
      <div className="container">
        <br/>
        <form onSubmit={formik.handleSubmit}>

          <ThemeProvider theme={theme}>
            <Grid container direction="row" alignItems="center" justifyContent="center">
              <Grid paddingTop={1}>
                <AccountBoxIcon style={{fontSize: "45px"}} color="primary"/>&nbsp; &nbsp;
              </Grid>
              <Grid style={{width: "40%"}}>
              <TextFieldInput autoFocus id="name" label="Nome" name="name" formik={formik} />                                  
              </Grid>
            </Grid>

            <Grid container direction="row" alignItems="center" justifyContent="center">
              <Grid item paddingTop={1}>
                <PhoneIcon style={{fontSize: "45px"}} color="primary"/>&nbsp; &nbsp;
              </Grid>
              <Grid item>
              <PhoneInput id="phone" label="Telefone" name="phone" formik={formik} />                   
              </Grid> 
            </Grid>

            <Grid container direction="row" alignItems="center" justifyContent="center">
              <Grid paddingTop={1}>
                <EmailIcon style={{fontSize: "45px"}} color="primary"/>&nbsp; &nbsp;
              </Grid>
              <Grid style={{width: "40%"}}>
              <TextFieldInput id="email" label="Email" name="email" type="email" formik={formik} />
              </Grid>
            </Grid>
          
            <Grid container direction="row" alignItems="center" justifyContent="center">
              <Grid paddingTop={1}>
                <MarkunreadMailboxIcon style={{fontSize: "45px"}} color="primary"/>&nbsp; &nbsp;
              </Grid>
              <Grid style={{width: "40%"}}>
              <CepInput id="zipcode" label="CEP" name="zipcode" formik={formik} />
              </Grid>
            </Grid>

            <Grid container direction="row" alignItems="center" justifyContent="center">
              <Grid paddingTop={1}>
                <PlaceIcon style={{fontSize: "45px"}} color="primary"/>&nbsp; &nbsp;
              </Grid>
              <Grid style={{width: "40%"}}>
                <TextFieldInput id="street" label="Rua" name="street" formik={formik} />
              </Grid>
            </Grid>
          
            <Grid container direction="row" alignItems="center" justifyContent="center">
              <Grid paddingTop={1}>
                <NumbersIcon style={{fontSize: "45px"}} color="primary"/>&nbsp; &nbsp;
              </Grid>
              <Grid style={{width: "40%"}}>                 
                <TextFieldInput id="number" label="Número" name="number" formik={formik} />
              </Grid>
            </Grid>
          
            <Grid container direction="row" alignItems="center" justifyContent="center">
              <Grid paddingTop={1}>
                <VillaIcon style={{fontSize: "45px"}} color="primary"/>&nbsp; &nbsp;
              </Grid>
              <Grid style={{width: "40%"}}>
                <TextFieldInput id="neighborhood" label="Bairro" name="neighborhood" formik={formik} />
              </Grid>
            </Grid>
           
            <Grid container direction="row" alignItems="center" justifyContent="center">
              <Grid paddingTop={1}>
                <PinDropIcon style={{fontSize: "45px"}} color="primary"/>&nbsp; &nbsp;
              </Grid>
              <Grid style={{width: "40%"}}>
              <Autocomplete                         
                id="state"
                options={states}        
                getOptionLabel={(option) => option.sigla ? option.sigla : ''}  
                value={state}
                onChange={(e, value) => setState(value)}
                renderInput={(params) => <TextField autocomplete="off" {...params} label="Estado" variant='standard'  />}
              />                     
              </Grid>
            </Grid>

            <Grid container direction="row" alignItems="center" justifyContent="center">
              <Grid paddingTop={1}>
                <LocationCityIcon style={{fontSize: "45px"}} color="primary"/>&nbsp; &nbsp;
              </Grid>
              <Grid style={{width: "40%"}}>
              <Autocomplete
                disablePortal
                disabled={!state.sigla}
                id="city"
                options={citys}        
                getOptionLabel={(option) => option.nome ? option.nome : ''}  
                value={city}
                onChange={(e, value) => setCity(value)}   
                renderInput={(params) => <TextField autocomplete="off" {...params} label="Cidade" variant='standard'/>}
              />
              </Grid>
            </Grid>
                        
          
            <Grid container 
              direction="row" 
              alignItems="center" 
              justifyContent="center"
              paddingTop={1}
              paddingBottom={3}
            >
              <Grid paddingTop={1}>
                <HvacIcon style={{fontSize: "45px"}} color="primary"/>
              </Grid>
              <Grid style={{width: "30%"}}>
              <TextFieldInput id="latitude" label="Latitude" name="latitude" formik={formik} />                                  
              </Grid>&nbsp;

              <Grid paddingTop={1}>
                <HvacIcon 
                  style={{
                      fontSize: "45px", 
                      transform: "rotate(90deg)"
                    }} 
                  color="primary"/>
              </Grid>
              <Grid style={{width: "30%"}}>
              <TextFieldInput id="longitude" label="Longitude" name="longitude" formik={formik} />                                                   
              </Grid>&nbsp;

              <Grid paddingTop={1}>
                <IconButton className="btn"
                  onClick={() => openMap()}
                  style={{
                    backgroundColor: "#F85F2F",
                    color: "white"
                  }}>
                  <AddLocationIcon />
                </IconButton>
              </Grid>

            </Grid>
          </ThemeProvider>

          <Modal.Footer>
            <button className="btn"
            type='submit'
              style={{
                backgroundColor: "#F85F2F",
                color: "white"
              }}>
                 {id ? 'Editar': 'Adicionar'}
            </button>
          </Modal.Footer>
        </form>
      </div>
    </Modal>

    <Modal 
      size="lg" style={{ backgroundColor: "gray" }} show={showMap} onHide={() => closeMap()}>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "30px" }} className="laranja">
          Selecione a Localização da Salina
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={(e) => submitMap(e)}>
        <Grid padding={1}>
          <Coordinates 
            className="map" 
            latitude={latitude}
            setLatitude={setLatitude}
            longitude={longitude}
            setLongitude={setLongitude}
            coordinates={defaultCoordinates}
            onChange= {(e) => console.log(e)}
          />
        </Grid>
        <Modal.Footer>
          <button className="btn"
            style={{
              backgroundColor: "#F85F2F",
              color: "white"
            }}>
            Selecionar
          </button>
        </Modal.Footer>
      </form>
    </Modal>
    </Box>
  )
}
