import React, { useMemo, useState } from "react";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import { useEffect } from "react";
import "../styles/map.css";
import { Divider, Grid } from "@mui/material";

const Coordinates = (props) => {

  const {
    latitude,
    setLatitude,
    longitude,
    setLongitude,
    coordinates,
  } = props;

  const markerCoors = useMemo(() => ({
    lat: parseFloat(latitude),
    lng: parseFloat(longitude)
  }), [latitude, longitude]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyA9J4pl06UlM8nHuE_6Hd7teVs_eWMx4ZQ",
  });

  const [map, setMap] = useState([]);
  const [center, setCenter] = useState(
    latitude 
      ? {
          initialLatitude: latitude,
          initialLongitude: longitude,
        }
      : {
          initialLatitude: coordinates.latitude,
          initialLongitude: coordinates.longitude,
        }
  );

  useEffect(() => {
    setMap(
      <GoogleMap
        mapTypeId="satellite"
        mapContainerStyle={{ width: "100%", height: "95%" }}
        onClick={ev => {
          setLatitude(ev.latLng.lat());
          setLongitude(ev.latLng.lng());
          setCenter(latitude, longitude);
        }}
        center={{
          lat: parseFloat(center.initialLatitude),
          lng: parseFloat(center.initialLongitude),
        }}
        zoom={15}
      >
        <MarkerF position={markerCoors} />
      </GoogleMap>
    );
  }, [center, latitude, setLatitude, longitude, setLongitude, markerCoors]);

  return (
    <Grid className="map">
      <Divider>Latitude: {latitude} - Longitude: {longitude}</Divider>
      {isLoaded ? map : <></>}
    </Grid>
  );
};

export default Coordinates;
