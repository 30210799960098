import React from 'react';

import {
    Box,
    Card,
    CardContent,
    CardHeader,
  } from '@mui/material';

import EvolutionChart from './EvolutionChart';

const CardEvolutionChart = (props) => {


    return (
        <Box sx={{ minWidth: 275 }}>
            <Card variant="outlined">
                <CardHeader 
                    style={{ backgroundColor: "#F85F2F", color: "white" }} 
                    title="Evolução Geral"
                />
                <React.Fragment>
                    <CardContent>
                        <EvolutionChart />
                    </CardContent>    
                </React.Fragment>
            </Card>
        </Box>
    )

}

export default CardEvolutionChart;