import { api } from "./api";

export async function getSalterns(company_id) {
  return await api
    .get("/salterns?company_id=" + company_id)
    .then((response) => {
      return response.data
    })
    .catch((err) => console.error(err));
}

export async function searchSaltern(saltern_id) {
  return await api
    .get("/salterns/" + saltern_id)
    .then((response) => {
      return response.data;
    })
    .catch((error) => console.error(error));
}
