import React from "react";
import NavBar from "../../components/Stations/NavBar";
import SideBar from "../../components/Stations/SideBar";
import Stations from "../../components/Stations/Stations";
import { Box, Stack } from "@mui/material";
import { useParams } from "react-router-dom";

const StationsPage = () => {

  const { idCompany, idSaltern } = useParams();
  const { nameCompany, nameSaltern } = useParams();

  return(
    <Box>
      <NavBar 
        idCompany={idCompany} 
        nameCompany={nameCompany} 
        idSaltern={idSaltern} 
        nameSaltern={nameSaltern}
      />
      <Stack direction="row" spacing={1} justify="space-between">
        <SideBar 
          idCompany={idCompany} 
          nameCompany={nameCompany} 
          idSaltern={idSaltern} 
          nameSaltern={nameSaltern}
        />
        <Stations />
      </Stack>
    </Box>
  );
}

export default StationsPage;