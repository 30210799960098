import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { 
  Box, 
  Button, 
  Fab, 
  Paper,
  Table, 
  TableBody, 
  TableContainer, 
  TableHead, 
  TableRow,
  Tooltip,
} from "@mui/material";

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import PreviewIcon from "@mui/icons-material/Preview";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Spinner } from "react-bootstrap";
import { getSalterns } from "../../services/salterns";


import { api } from "../../services/api";
import FormSaltern from "./FormSaltern";
import SnackbarComponent from "../GeneralElements/Snackbar";
import TableComponent from "../Table/TableComponent";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F85F2F",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
 
const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'phone', label: 'Telefone', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: ['street', 'number', 'neighborhood', 'city', 'state'], label: 'Endereco', alignRight: false },
  { id: 'zipcode', label: 'CEP', alignRight: false },
];

export default function Salterns(props) {
   //Definição de Tema
   const theme = createTheme({
    palette: {
      primary: {
        main: "#F85F2F",
        light: "#F85F2F",
        dark: "#F85F2F",
      },
    },
  });


  const navigate = useNavigate();
  const { idCompany, nameCompany } = useParams();

  const [id, setId] = useState(undefined);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [status, setStatus] = useState();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [salterns, setSalterns] = useState([]);

  const handleShow = () => {
    setId(null);    
    setShow(true);
  }

  const handleShowEdit  = () =>  setShow(true);

  const handleClose = () => {   
    setShow(false);
  }

  const handleCloseSnackBar = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const [data, setData] = useState({
    name: "",
    company_id: idCompany,
    street: "",
    city: "",
    zipcode: "",
    neighborhood: "",
    state: "",
    number: "",
    phone: "",
    email: "",
    latitude: "",
    longitude: "",
  });

 
  let contador = 0;

  const display = salterns
    .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
    .map((item) => {
      contador++;

      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell sx={{ color: "#F85F2F", fontWeight:"bold" }} >{item.name}</StyledTableCell>
          <StyledTableCell>{nameCompany}</StyledTableCell>
          <StyledTableCell>{item.phone}</StyledTableCell>
          <StyledTableCell>{item.email}</StyledTableCell>
          <StyledTableCell>
            {
              (item.street === null || item.street === "") ? "" : item.street + ", "
            }
            {
              (item.number === null || item.number === "") ? "" : item.number + ", " 
            }
            {
              (item.neighborhood === null || item.neighborhood === "") ? "" : item.neighborhood + ", "
            }
            {
              (item.city === null || item.city === "") ? "" : item.city + ", "
            }
            {
              (item.state === null || item.state === "") ? "" : item.state
            }
          </StyledTableCell>
          <StyledTableCell>{item.zipcode}</StyledTableCell>
          <ThemeProvider theme={theme}>
            <StyledTableCell className="text-center">
              <Button onClick={() => nav(item.id, item.name)}>
                <PreviewIcon theme={theme} color="primary" />
              </Button>
              <Button onClick={() => update(item)}>
                <EditIcon theme={theme} color="primary" />
              </Button>
              <Button onClick={() => remove(item.id)}>
                <DeleteIcon theme={theme} color="primary" />
              </Button>
            </StyledTableCell>
          </ThemeProvider>
        </StyledTableRow>
      )
    }
  );

  function remove(id) {
      api
        .delete("/salterns/" + id)
        .then((res) => {
          const myalldata = salterns.filter((item) => item.id !== id);
          setSalterns(myalldata);
          setStatus("success");
          setOpenSnackBar(true);
        })
        .catch(
          (err) => {
            console.error(err);
            setStatus("error");
            setOpenSnackBar(true);
          }
        );
  }

  function handle(e) {
    if(e){
    const tempData = salterns;
    tempData.push(e)
    //setSalterns(tempData);
    }
    handleClose();
  }

  function callbackEdit(item) {
    const tempSalterns = salterns;
    const indexItem = salterns.findIndex(element => element.id === item.id);
    tempSalterns[indexItem] = item;
    setSalterns(tempSalterns); 
    handleClose();
  }

  function update(item) {
    setId(item.id);
    handleShowEdit();
    setData(item);    
  }

  function nav(id_saltern, name_saltern) {
    navigate(`/stations/${idCompany}/${nameCompany}/${id_saltern}/${name_saltern}`);
  }

  useEffect(() => {
    (async () => {
      const response = await getSalterns(idCompany);
      setSalterns(response);
      setLoading(false);
    })();
  }, [data, idCompany]);

  return (
    <Box flex={6} p={2}> 
      <br />
      <h2 className="laranja">Salinas da {nameCompany}</h2>

      <FormSaltern theme= {theme} id={id} idCompany={idCompany} dataSaltern={data} show={show} callbackInsert = {handle}  handleClose = {handleClose} callbackEdit = {callbackEdit} setStatus={setStatus} setOpenSnackBar={setOpenSnackBar}/>

      <TableComponent tableHead={TABLE_HEAD} data={salterns} removeFunction={id => remove(id)} editFunction={item => update(item)} navFunction={(item) => nav(item.id, item.name)}/>
      
      {/* <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell width="10%" align="center" >Nome</StyledTableCell>
              <StyledTableCell width="10%" align="center">Companhia</StyledTableCell>
              <StyledTableCell width="10%" align="center">Telefone</StyledTableCell>
              <StyledTableCell width="10%" align="center">E-mail</StyledTableCell>
              <StyledTableCell width="20%" align="center">Endereço</StyledTableCell>
              <StyledTableCell width="10%" align="center">CEP</StyledTableCell>
              <StyledTableCell width="20%" align="center">Ações</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {
            loading ? (
              <TableRow>
                <TableCell>
                  <Spinner animation="border" role="status" variant="primary">
                      <span className="visually-hidden">Carregando...</span>
                  </Spinner>
                </TableCell>
              </TableRow>
            ) : (
              contador !== 0 
                ? display
                : <TableRow>
                    <TableCell colSpan={7} align="center">
                      Nenhuma salina cadastrada.
                    </TableCell>
                  </TableRow>
            )
          }
          </TableBody>
        </Table>
        </TableContainer> */}
      <br />
      <Box className="float-end">
        <Box sx={{ "& > :not(style)": { m: 1 } }}>
          <ThemeProvider theme={theme}>
            <Tooltip title="Adicionar Salina" color="primary" onClick={handleShow}>
              <Fab theme={theme} color="primary" aria-label="add">
                <AddIcon />
              </Fab>
            </Tooltip>
          </ThemeProvider>
        </Box>
      </Box>     
      <SnackbarComponent openSnackBar={openSnackBar} handleCloseSnackBar={handleCloseSnackBar} status={status}/>
    </Box>
  );
}
