import * as React from 'react';
import { Link } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import smartsaltLogo from '../images/smartsaltLogo.png';
import { useContext } from 'react';
import { AuthContext } from '../contexts/auth';

const DefaultNavBar = (props) => {
    const idSaltern = props.idSaltern;
    const nameSaltern = props.nameSaltern;

    const user = JSON.parse(localStorage.getItem("user"));

    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const {logout} = useContext(AuthContext);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = () => {
        logout();
    };

    return (
        <AppBar position="static" color="transparent">
            <Container maxWidth="false">
                <Toolbar disableGutters justify="space-between" display="flex">


                    {/* NavBar Grupos e Salinas - Relatórios Employees */}

                    <Typography
                        variant="h6"
                        noWrap
                        component={Link} 
                        to={
                            user["tipo"] === "admins" 
                                ? "/"
                                
                            : user["tipo"] === "companies" 
                                ? `/saltern/${user.id}/${user.name}`

                            : user["tipo"] === "employees" 
                                ? `/stations/${user.id}/${user.company}/${idSaltern}/${nameSaltern}`

                            : null
                        }
                        sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                    >
                        <img className="logo" src={smartsaltLogo} alt="Logo"/>
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} />


                    {/* NavBar Grupos e Salinas Responsiva - Relatórios Employees */}

                    <Typography
                        variant="h6"
                        noWrap
                        component={Link} 
                        to={
                            user["tipo"] === "admins" 
                                ? "/"
                                
                            : user["tipo"] === "companies" 
                                ? `/saltern/${user.id}/${user.name}`

                            : user["tipo"] === "employees" 
                                ? `/stations/${user.id}/${user.company}/${idSaltern}/${nameSaltern}`

                            : null
                        }
                        sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                    >
                        <img className="logo" src={smartsaltLogo} alt="Logo"/>
                    </Typography>
                
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} />

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip  title="Perfil do Usuário">
                            <IconButton  onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar bgcolor="#F85F2F" alt="Admin" />
                            </IconButton>
                        </Tooltip>
                
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem onClick={handleCloseUserMenu}>
                                <Typography sx={{ width: 150, ml: 2}}>Conta</Typography>
                            </MenuItem>
                            <MenuItem onClick={handleCloseUserMenu}>
                                <Typography sx={{ width: 150, ml: 2}} to={'/configs'} component={Link}>Configurações</Typography>
                            </MenuItem>
                            <MenuItem onClick={handleCloseUserMenu}>
                                <Typography sx={{ width: 150, ml: 2 }} onClick={handleLogout}>Sair</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default DefaultNavBar;
