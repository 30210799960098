import React from "react";
import DefaultNavBar from "../../components/DefaultNavBar";
import Weather from "../../components/Weather";
import { Box, Stack } from "@mui/material";
import "bootstrap/dist/css/bootstrap.css";
import "../../styles/home.css";
import Configs from "../../components/Configs/Configs";

const ConfigsPage = () => {
  return (
    <Box>
      <DefaultNavBar />
      <Stack direction="row" spacing={1} justify="space-between">
        <Weather />
        <Configs />
      </Stack>
    </Box>
  );
};

export default ConfigsPage;
