import React from "react";
import DefaultNavBar from "../../components/DefaultNavBar";
import Weather from "../../components/Weather";
import Salterns from "../../components/Salterns/Salterns";
import { Box, Stack } from "@mui/material";

const SalternsPage = () => {
  return (
    <Box>
      <DefaultNavBar />
      <Stack direction="row" spacing={1} justify="space-between">
        <Weather />
        <Salterns />
      </Stack>
    </Box>    
  );
};

export default SalternsPage;
