import * as Yup from 'yup';
import { useState, useContext } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel, Button, FormLabel, Typography } from '@mui/material';
import { AuthContext } from '../../contexts/auth';

export default function LoginForm() {
    const navigate = useNavigate();
  const {login} = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('O Email precisa ser valido').required('Email é obrigatorio'),
    password: Yup.string().required('A senha é obrigatoria'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      formik.setSubmitting(true);   
      login(formik.values.email, formik.values.password).catch((reason) => {
        console.error(reason);
        setError('E-mail e/ou senha inválidos!')
        //setLoading(false);
    })
       
      //await login(formik.values.email, formik.values.password);
      formik.setSubmitting(false);      
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
      {
                            error 
                            ? <FormLabel style={{ color: "red" }}>
                                {error}
                                <br/>
                                <br/>
                              </FormLabel> 
                            : null
      }

        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Endereço de email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Senha"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Lembrar-me"
          />

          <Link component={RouterLink} variant="subtitle2" to="/forgotpassword" underline="hover">
            Esqueceu a senha?
          </Link>         
        </Stack>
        <Typography variant="body2" sx={{ mb: 1 }}>
             Não tem uma conta ainda {''}
              <Link variant="subtitle2" component={RouterLink} to="/register">
                Inicie agora
              </Link>
            </Typography>
        <Button fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}       
        >
          Login
        </Button>
      </Form>
    </FormikProvider>
  )
}
