import * as Yup from 'yup';
import { useState, useContext } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel, Button, FormLabel, Typography } from '@mui/material';
import { AuthContext } from '../../contexts/auth';

export default function ResetPasswordForm() {
    const navigate = useNavigate();
  const {login} = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState();

  const LoginSchema = Yup.object().shape({
    password: Yup.string().required('A senha é obrigatoria'),
    passwordConfirmation: Yup.string().required('A confirmação de senha é obrigatoria'),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      formik.setSubmitting(true);   
      login(formik.values.email, formik.values.password).catch((reason) => {
        console.error(reason);
        setError('E-mail e/ou senha inválidos!')
        //setLoading(false);
    })
       
      //await login(formik.values.email, formik.values.password);
      formik.setSubmitting(false);      
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
      {
                            error 
                            ? <FormLabel style={{ color: "red" }}>
                                {error}
                                <br/>
                                <br/>
                              </FormLabel> 
                            : null
      }

        <Stack spacing={3} mb={4}>

          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            label="Senha"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            label="Confirmação de Senha"
            {...getFieldProps('passwordConfirmation')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
            helperText={touched.passwordConfirmation && errors.passwordConfirmation}
          />
        </Stack>

        <Button fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}       
        >
          Login
        </Button>
      </Form>
    </FormikProvider>
  )
}
