import * as React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import AnalyticsIcon from "@mui/icons-material/Analytics";

import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ThunderstormOutlinedIcon from "@mui/icons-material/ThunderstormOutlined";
import ThermostatOutlinedIcon from "@mui/icons-material/ThermostatOutlined";
import WindPowerOutlinedIcon from "@mui/icons-material/WindPowerOutlined";
import { getWeather } from "../services/weather";
import { searchSaltern } from "../services/salterns";
import { useState, useEffect } from "react";

export default function SideBar(props) {
  const idCompany = props.idCompany;
  const nameCompany = props.nameCompany;
  const idSaltern = props.idSaltern;
  const nameSaltern = props.nameSaltern;

  const user = JSON.parse(localStorage.getItem("user"));

  const [weather, setWeather] = useState({
    wind: "",
    temp: "",
    clouds: "",
  });

  useEffect(() => {
    (async () => {
      const data = await searchSaltern(idSaltern);
      const temp = await getWeather(data.latitude, data.longitude);
      setWeather(temp);
    })();
  }, [idSaltern]);

  return (
    <Box flex={1} p={2} sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
      <List>
        {
          user["tipo"] !== "employees" ?
            <ListItem component={Link} to={"/saltern/" + idCompany + "/" + nameCompany}>
              <ListItemButton sx={{ color: "#F85F2F" }}>
                <ListItemIcon sx={{ color: "#F85F2F" }}>
                  <GroupWorkIcon />
                </ListItemIcon>
                <ListItemText primary="Salinas" />
              </ListItemButton>
            </ListItem>
          : null
        }
        
        {
          user["tipo"] !== "employees" ?
            <ListItem
              component={Link}
              to={"/reports/" + idCompany + "/" + nameCompany + "/" + idSaltern + "/" + nameSaltern}
            >
              <ListItemButton sx={{ color: "#F85F2F" }}>
                <ListItemIcon sx={{ color: "#F85F2F" }}>
                  <AnalyticsIcon />
                </ListItemIcon>
                <ListItemText primary="Relatórios" />
              </ListItemButton>
            </ListItem>
        : null
        }
      </List>
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
          marginTop: "10%",
          paddingLeft: "5%",
        }}
      >
        <ListItem sx={{ color: "#F85F2F" }}>
          <ListItemAvatar>
            <Avatar>
              <ThunderstormOutlinedIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Chuva" secondary={weather.clouds + " %"} />
        </ListItem>
        <ListItem sx={{ color: "#F85F2F" }}>
          <ListItemAvatar>
            <Avatar>
              <ThermostatOutlinedIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Temperatura" secondary={weather.temp + " °C"} />
        </ListItem>
        <ListItem sx={{ color: "#F85F2F" }}>
          <ListItemAvatar>
            <Avatar>
              <WindPowerOutlinedIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Vento" secondary={weather.wind + " m/s"} />
        </ListItem>
      </List>
    </Box>
  );
}

/*
<Box>
<Paper elevation={3}>
  <div className="float-start">
    <p>
      <span className="laranja"> Chuva</span>
      <br/>
      <ThunderstormOutlinedIcon /> 
      <span className="laranja"> 55%</span>
    </p>
  </div>
  <div className="float-start">
    <p>
      <span className="laranja"> Temperatura</span>
      <br/>
      <ThermostatOutlinedIcon /> 
      <span className="laranja">27°C</span> 
    </p>
  </div>
  <div className="float-start">
    <p>
      <span className="laranja"> Vento</span>
      <br/>
      <WindPowerOutlinedIcon /> 
      <span className="laranja">2m/s</span>
    </p>
  </div>
</Paper>
</Box>
*/
