import { api } from "./api";

export default async function getCompanies() {
  return await api
    .get("/companies")
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.error(err));
}

export async function searchCompany(company_id) {
  return await api
    .get("/companies/" + company_id)
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.error(err));
}