import { Alert, Snackbar } from '@mui/material'
import React from 'react'

export default function SnackbarComponent({openSnackBar, handleCloseSnackBar, status,  message}) {
  return (
    <Snackbar open={openSnackBar} autoHideDuration={2000} onClose={() => handleCloseSnackBar(!openSnackBar)}>
    <Alert onClose={() => handleCloseSnackBar(!openSnackBar)} variant="filled" severity={status} sx={{ width: '100%' }}>
      {
        message  ?
        <span>{message}</span>
        : status === "success" ? 
          <span>Operação realizada com sucesso!</span>
        : status === "error" ?
          <span>Erro!</span>      
        : null
      }
    </Alert>
  </Snackbar>
  )
}
