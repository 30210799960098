import { api } from "./api";
import moment from "moment";

export async function getMeasurements(station_id) {
  return await api
    .get(`/measurements?station_id=${station_id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => err.response);
}

export async function downloadCSV(station_id, startDate, endDate, selectedOptions) {
  const response = await getMeasurements(station_id);

  let dataCSV = [];

  selectedOptions.forEach((option) => {
      dataCSV.push([` ${option.name} (${option.unit}) `, `Data da Coleta`]);

      const dataTemp = response.data
        .sort((a, b) => new Date(a.collected_start) - new Date(b.collected_start))
        .filter(
          (item) =>
            item.property_id === option.id &&
            item.collected_start >= moment(startDate).format("YYYY-MM-DD") + "T00:00:00.000Z" &&
            item.collected_start <= moment(endDate).format("YYYY-MM-DD") + "T00:00:00.000Z"
        )
        .map((item) => {
          moment.locale("pt-br");
          return [item.value, moment(item.collected_start).format("L")];
        });

      dataCSV.push(...dataTemp);
  
  });

  return dataCSV;
}

export function checkMeasurements(property_id, value) {
  switch (property_id) {
    case 1:
      if (value < 6) {
        return true;
      }
      break;
    case 2:
      if (value < 50) {
        return true;
      }
      break;
    case 3:
      if (value < 32) {
        return true;
      }
      break;

    default:
      break;
  }
  return false;
}

export async function addMeasurements(data) {
  return await api
    .post(`/measurements`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => err.response);
}

export async function editMeasurements(data) {
  return await api
    .put(`/measurements/${data.id}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => err.response);
}

export async function deleteMeasurements(id) {
  return await api
    .delete(`/measurements/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => err.response);
}
