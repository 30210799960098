import React from "react";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import Members from "../../components/Members/Members";
import { Box, Stack } from "@mui/material";
import { useParams } from "react-router-dom";

const MembersPage = () => {

  const {idCompany, idSaltern} = useParams();
  const nameCompany = useParams();
  const nameSaltern = useParams();

  const json = JSON.stringify(nameCompany.nameCompany);
  const company = json.replace(/"/g, "");

  const json2 = JSON.stringify(nameSaltern.nameSaltern);
  const saltern = json2.replace(/"/g, "");

  return (
    <Box>
      <NavBar 
        idCompany={idCompany} 
        nameCompany={company} 
        idSaltern={idSaltern} 
        nameSaltern={saltern}
      />
      <Stack direction="row" spacing={1} justify="space-between">
        <SideBar 
          idCompany={idCompany} 
          nameCompany={company} 
          idSaltern={idSaltern} 
          nameSaltern={saltern}
        />
        <Members idSaltern={idSaltern} idCompany={idCompany} />
      </Stack>
    </Box>
  );
};

export default MembersPage;
