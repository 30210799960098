import React, { useEffect, useState } from "react";

import { Box, Grid } from "@mui/material";

import { /*averageReportsByStation,*/ filterReports, getReports } from "../../services/reports";
import CardChart from "./CardChart";
//import CardEvolutionChart from "./CardEvolutionChart";
import CardsReport from "./CardsReport";
import CardEvolutionChart from "./CardEvolutionChart";
import { api } from "../../services/api";

const Reports = (props) => {
  const { saltern_id } = props;
  localStorage.setItem('saltern_id',saltern_id);
  const [stations, setStations] = useState([]);
  const [measurements, setMeasurements] = useState();
  const [evolutionReports, setEvolutionReports] = useState();

  useEffect(() => {
    (async () => {
      let { measurements } = await getReports(saltern_id);
      //measurements = await filterReports(measurements);
      setMeasurements(measurements);
      await api
      .get("/stations?saltern_id=" + saltern_id)
      .then(res => {
          setStations(res.data);
      })
      // let { evolutionReports } = await getReports(saltern_id);
      // evolutionReports = await averageReportsByStation(evolutionReports);
      // setEvolutionReports(evolutionReports);
    })();
  }, [saltern_id]);

  return (
    <Box flex={6} p={2}>
      <br />
      <h2 className="laranja">Relatórios</h2>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={5}>
          <CardsReport measurements={measurements} saltern_id={saltern_id}></CardsReport>
        </Grid>
        <Grid item xs={12} sm={12} md={7}>
          <CardChart saltern_id={saltern_id} />
          <br />
          <CardEvolutionChart stations={stations} evolutionReports={evolutionReports} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Reports;
