import React, { useState } from "react";

import {
  Alert,
  Box,
  Button,
  Fab,
  Grid,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LockIcon from "@mui/icons-material/Lock";
import { Spinner, Modal } from "react-bootstrap";

import { api } from "../../services/api";
import TableComponent from "../Table/TableComponent";

const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'phone', label: 'Telefone', alignRight: false },
];
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F85F2F",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TableMembers = (props) => {

  const { 
    data,
    setData,
    members, 
    setMembers, 
    company, 
    saltern, 
    idCompany,
    idSaltern,
    loading,
  } = props;

  const theme = createTheme({
    palette: {
      primary: {
        main: "#F85F2F",
        light: "#F85F2F",
        dark: "#F85F2F",
      },
    },
  });

  const user = JSON.parse(localStorage.getItem("user"));

  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [status, setStatus] = useState();

  const handleShow = () => setShow(true);
  const handleShowEdit = () => setShowEdit(true);

  const handleClose = () => {
    setShow(false);
    setData({
      name: "",
      company_id: idCompany,
      saltern_id: idSaltern,
      email: "",
      password: "",
      phone: "",
    });
  };

  const handleCloseEdit = () => {
    setShowEdit(false);
    setData({
      name: "",
      company_id: idCompany,
      saltern_id: idSaltern,
      email: "",
      password: "",
      phone: "",
    });
  };

  function submit(e) {
    e.preventDefault();
    api
      .post("/employees", data)
      .then((res) => {
        const mydata = [...members, res.data];
        setMembers(mydata);
        setStatus("success");
        setOpenSnackBar(true);
        handleClose();
      })
      .catch(
        (err) => {
          console.error(err);
          setStatus("error");
          setOpenSnackBar(true);
        }
      );
  }

  function submitEdit(e) {
    e.preventDefault();
    api
      .put(`/employees/${data.id}`, data)
      .then((res) => {
        setStatus("success");
        setOpenSnackBar(true);
        handleCloseEdit();
      })
      .catch(
        (err) => {
          console.error(err);
          setStatus("error");
          setOpenSnackBar(true);
        }
      );
  }

  function remove(id) {
    if (window.confirm("Tem certeza de que deseja excluir este funcionário?")) {
      api
        .delete(`/employees/${id}`)
        .then((res) => {
          const myalldata = members.filter((item) => item.id !== id);
          setMembers(myalldata);
          setStatus("success");
          setOpenSnackBar(true);
        })
        .catch(
          (err) => {
            console.error(err);
            setStatus("error");
            setOpenSnackBar(true);
          }
        );
    }
  }

  function handle(e) {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  }

  function update(item) {
    handleShowEdit();
    setData(item);
  }

  const handleCloseSnackBar = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  let contador = 0;

  const display = members
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
    .map((item) => {
      contador++;

      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="center" sx={{ color: "#F85F2F", fontWeight: "bold" }}>
            {item.name}
          </StyledTableCell>
          <StyledTableCell align="center">{item.phone}</StyledTableCell>
          {
            user["tipo"] === "admins" ? 
              <StyledTableCell align="center">{company?.name}</StyledTableCell> 
            : null
          }
          {
            user["tipo"] === "admins" ?
              <StyledTableCell align="center">{company?.phone}</StyledTableCell>
            : null
          }
          <StyledTableCell align="center">{saltern?.name}</StyledTableCell>
          <StyledTableCell align="center">{saltern?.phone}</StyledTableCell>
          <ThemeProvider theme={theme}>
            <StyledTableCell align="center" className="text-center">
              <Button onClick={() => update(item)}>
                <EditIcon theme={theme} color="primary" />
              </Button>
              <Button onClick={() => remove(item.id)}>
                <DeleteIcon theme={theme} color="primary" />
              </Button>
            </StyledTableCell>
          </ThemeProvider>
        </StyledTableRow>
      )
    }
  );

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="laranja">Adicionar Funcionário</Modal.Title>
        </Modal.Header>
        <div className="container">
          <br />
          <form onSubmit={(e) => submit(e)}>
            <ThemeProvider theme={theme}>
              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid paddingTop={1}>
                  <AccountBoxIcon style={{ fontSize: "45px" }} color="primary" />
                  &nbsp; &nbsp;
                </Grid>
                <Grid style={{width: "40%"}}>
                  <TextField
                    label="Nome"
                    InputLabelProps={{ required: false }}
                    onChange={(e) => handle(e)}
                    value={data.name}
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    variant="standard"
                    required
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid paddingTop={1}>
                  <EmailIcon style={{ fontSize: "45px" }} color="primary" />
                  &nbsp; &nbsp;
                </Grid>
                <Grid style={{width: "40%"}}>
                  <TextField
                    label="E-mail"
                    InputLabelProps={{ required: false }}
                    onChange={(e) => handle(e)}
                    value={data.email}
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    variant="standard"
                    required
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid paddingTop={1}>
                  <PhoneIcon style={{ fontSize: "45px" }} color="primary" />
                  &nbsp; &nbsp;
                </Grid>
                <Grid style={{width: "40%"}}>
                  <TextField
                    label="Telefone"
                    InputLabelProps={{ required: false }}
                    onChange={(e) => handle(e)}
                    value={data.phone}
                    type="text"
                    name="phone"
                    id="phone"
                    className="form-control"
                    variant="standard"
                    required
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid paddingTop={1}>
                  <LockIcon style={{ fontSize: "45px" }} color="primary" />
                  &nbsp; &nbsp;
                </Grid>
                <Grid style={{width: "40%"}}>
                  <TextField
                    label="Senha"
                    InputLabelProps={{ required: false }}
                    onChange={(e) => handle(e)}
                    value={data.password}
                    type="password"
                    name="password"
                    id="password"
                    className="form-control"
                    variant="standard"
                    required
                  />
                </Grid>
              </Grid>
              <br />
            </ThemeProvider>

            <Modal.Footer>
              <button
                className="btn"
                style={{
                  backgroundColor: "#F85F2F",
                  color: "white",
                }}
              >
                Adicionar
              </button>
            </Modal.Footer>
          </form>
        </div>
      </Modal>

      <Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title className="laranja">Editar Funcionário</Modal.Title>
        </Modal.Header>
        <div className="container">
          <br />
          <form onSubmit={(e) => submitEdit(e)}>
            <ThemeProvider theme={theme}>
              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid paddingTop={1}>
                  <AccountBoxIcon style={{ fontSize: "45px" }} color="primary" />
                  &nbsp; &nbsp;
                </Grid>
                <Grid style={{width: "40%"}}>
                  <TextField
                    label="Nome"
                    InputLabelProps={{ required: false }}
                    onChange={(e) => handle(e)}
                    defaultValue={data.name}
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    variant="standard"
                    required
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid paddingTop={1}>
                  <EmailIcon style={{ fontSize: "45px" }} color="primary" />
                  &nbsp; &nbsp;
                </Grid>
                <Grid style={{width: "40%"}}>
                  <TextField
                    label="E-mail"
                    InputLabelProps={{ required: false }}
                    onChange={(e) => handle(e)}
                    defaultValue={data.email}
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    variant="standard"
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid paddingTop={1}>
                  <PhoneIcon style={{ fontSize: "45px" }} color="primary" />
                  &nbsp; &nbsp;
                </Grid>
                <Grid style={{width: "40%"}}>
                  <TextField
                    label="Telefone"
                    InputLabelProps={{ required: false }}
                    onChange={(e) => handle(e)}
                    defaultValue={data.phone}
                    type="text"
                    name="phone"
                    id="phone"
                    className="form-control"
                    variant="standard"
                    required
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid paddingTop={1}>
                  <LockIcon style={{ fontSize: "45px" }} color="primary" />
                  &nbsp; &nbsp;
                </Grid>
                <Grid style={{width: "40%"}}>
                  <TextField
                    label="Senha"
                    InputLabelProps={{ required: false }}
                    onChange={(e) => handle(e)}
                    defaultValue={data.password}
                    type="password"
                    name="password"
                    id="password"
                    className="form-control"
                    variant="standard"
                  />
                </Grid>
              </Grid>
              <br />
            </ThemeProvider>

            <Modal.Footer>
              <button
                className="btn"
                style={{
                  backgroundColor: "#F85F2F",
                  color: "white",
                }}
              >
                Editar
              </button>
            </Modal.Footer>
          </form>
        </div>
      </Modal>

      <TableComponent tableHead={TABLE_HEAD} data={members} removeFunction={id => remove(id)} editFunction={item => update(item)} navFunction={null}/>
      {/* <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Nome</StyledTableCell>
              <StyledTableCell align="center">Telefone</StyledTableCell>
              {
                user["tipo"] === "admins" ?
                  <StyledTableCell align="center">Companhia</StyledTableCell>
                : null
              }
              {
                user["tipo"] === "admins" ?
                  <StyledTableCell align="center">Telefone da Companhia</StyledTableCell>
                : null
              }
              <StyledTableCell align="center">Salina</StyledTableCell>
              <StyledTableCell align="center">Telefone da Salina</StyledTableCell>
              <StyledTableCell width="20%" align="center">
                Ações
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              loading ? (
                <TableRow>
                  <TableCell>
                    <Spinner animation="border" role="status" variant="primary">
                      <span className="visually-hidden">Carregando...</span>
                    </Spinner>
                  </TableCell>
                </TableRow>
              ) : (
                contador !== 0 
                  ? display 
                  : <TableRow>
                      <TableCell colSpan={8} align="center">
                        Nenhum funcionário cadastrado.
                      </TableCell>
                    </TableRow>
              )
            }
          </TableBody>
        </Table>
          </TableContainer> */}
      <br />
      <Box className="float-end">
        <Box sx={{ "& > :not(style)": { m: 1 } }}>
          <ThemeProvider theme={theme}>
            <Tooltip title="Adicionar Funcionário" color="primary" onClick={handleShow}>
              <Fab theme={theme} color="primary" aria-label="add">
                <AddIcon />
              </Fab>
            </Tooltip>
          </ThemeProvider>
        </Box>
      </Box>

      <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
        <Alert onClose={handleCloseSnackBar} variant="filled" severity={status} sx={{ width: '100%' }}>
          {
            status === "success" ? 
              <span>Operação realizada com sucesso!</span>
            : status === "error" ?
              <span>Erro!</span>
            : null
          }
        </Alert>
      </Snackbar>

    </div>
  );
};

export default TableMembers;
