import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#F85F2F",
      light: "#F85F2F",
      dark: "#F85F2F",
    },
    secondary: {
      main: "#FFD700",
    },
    tertiary: {
      main: "#008000",
    },
  },
});

export default theme;
