import { api } from "./api";

export async function getStations(saltern_id) {
  return await api
    .get("/stations?saltern_id=" + saltern_id)
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.error(err));
}
