import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {  Stack, TextField,Button, FormControlLabel, Checkbox, DialogActions, DialogContentText, DialogContent, DialogTitle, Dialog, Typography } from '@mui/material';
import TextFieldInput from '../Inputs/TextFieldInput';
import { useState } from 'react';
import { Box } from '@mui/system';

export default function SignUpForm() {
  const [acceptUseterms, setAcceptUseterms] = useState(false)
  const [openUseTerms, setOpenUseTerms] = useState(false)
 
  const SignUpSchema = Yup.object().shape({
    fantasy_name: Yup.string().required('Campo Obrigatório'),
    cnpj: Yup.string().required('Campo Obrigatório'),
    owner: Yup.string().required('Campo Obrigatório'),
    email: Yup.string().required('Campo Obrigatório'),
    phone: Yup.string().required('Campo Obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
      fantasy_name: "",
      cnpj: "",
      owner: "",
      phone: "",
      email: "",
    },
    validationSchema: SignUpSchema,
    onSubmit: async () => {
      if(acceptUseterms){
      const smtpjs = window.Email;
      smtpjs
        .send({
          SecureToken: "c730827e-5c2c-4816-a1c2-96b73573d5d7",
          To: "smartsalt84@gmail.com",
          From: "danielcsouza97@gmail.com",
          Subject: "Solicitaçao de Cadastro no Sistema SmartSalt",
          Body: `
          Nome: ${values.name},
          Nome Fantasia: ${values.fantasy_name},
          CNPJ: ${values.cnpj},
          Dono: ${values.owner},
          Telefone: ${values.phone},
          E-mail: ${values.email},         
          `
        })
        .then((message) => alert(message))
        .catch(e => console.log(e.message));
      // navigate('/dashboard', { replace: true }); 
      }else{

      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Dialog
        open={openUseTerms}
        onClose={() => setOpenUseTerms(false)}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Termos de uso</DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            id="scrollContentText"/>
              <Typography component="div">
      <Box sx={{ fontWeight: 'bold', m: 1 }}>TERMO DE CONFIDENCIALIDADE E SIGILO</Box>
    </Typography>
    <Typography>
    Este Termo de Sigilo de Dados ("Termo") é celebrado entre o projeto SmartSalt e {formik.values.fantasy_name} ("Cliente"), portador do seguinte CNPJ: {formik.values.cnpj},  e estabelece as condições para a proteção e confidencialidade dos dados compartilhados entre as partes.</Typography>
<Typography><Box sx={{ fontWeight: 'medium', m: 1 }}>Definições</Box></Typography>
<Typography><Box  component="span" sx={{ fontWeight: 'medium', m: 1}}>1.1.</Box> Dados: Qualquer informação, incluindo, mas não se limitando a, dados pessoais, comerciais, técnicos ou financeiros, transmitidos, compartilhados ou fornecidos pelo projeto SmartSalt ao Cliente ou vice-versa, no âmbito deste Termo.</Typography>
<Typography><Box  component="span" sx={{ fontWeight: 'medium', m: 1 }}>1.2.</Box> Informação Confidencial: qualquer informação divulgada pelas partes que seja considerada confidencial, sensível ou proprietária, incluindo, mas não se limitando a, segredos comerciais, estratégias de negócios, know-how, projetos, documentos, dados, software, especificações, relatórios, análises, planos, métodos e técnicas.</Typography>
<Typography><Box sx={{ fontWeight: 'medium', m: 1 }}>Objetivo</Box></Typography>
<Typography><Box  component="span" sx={{ fontWeight: 'medium', m: 1 }}>2.1.</Box> O projeto SmartSalt e o Cliente reconhecem que, durante a execução de suas respectivas atividades, poderão compartilhar dados e informações confidenciais. Este Termo estabelece as obrigações de ambas as partes em relação à proteção e ao sigilo desses dados.</Typography>
<Typography><Box sx={{ fontWeight: 'medium', m: 1 }}>Confidencialidade e Uso Adequado dos Dados</Box></Typography>
<Typography><Box  component="span" sx={{ fontWeight: 'medium', m: 1 }}>3.1.</Box> As partes concordam em manter os dados compartilhados sob sigilo e não divulgar, fornecer, vender, transferir ou disponibilizar esses dados a terceiros sem o consentimento prévio por escrito da outra parte.</Typography>
<Typography><Box  component="span" sx={{ fontWeight: 'medium', m: 1 }}>3.2.</Box> As partes concordam em utilizar os dados compartilhados exclusivamente para os fins acordados e em conformidade com a legislação aplicável à proteção de dados pessoais e confidencialidade.</Typography>
<Typography><Box  component="span" sx={{ fontWeight: 'medium', m: 1 }}>3.3.</Box> O projeto SmartSalt e o Cliente se comprometem a implementar medidas de segurança adequadas para proteger os dados compartilhados contra acesso não autorizado, perda, divulgação, alteração ou destruição.</Typography>
<Typography><Box sx={{ fontWeight: 'medium', m: 1 }}>Responsabilidades</Box></Typography>
<Typography><Box component="span" sx={{ fontWeight: 'medium', m: 1 }}>4.1.</Box> O projeto SmartSalt e o Cliente designarão indivíduos autorizados, devidamente capacitados, para acessar e manipular os dados compartilhados. Esses indivíduos devem concordar em cumprir as obrigações de confidencialidade estabelecidas neste Termo.</Typography>
<Typography><Box component="span" sx={{ fontWeight: 'medium', m: 1 }}>4.2.</Box> As partes devem notificar imediatamente a outra parte caso tomem conhecimento de qualquer violação de segurança ou uso indevido dos dados compartilhados.</Typography>
<Typography><Box component="span" sx={{ fontWeight: 'medium', m: 1 }}>4.3.</Box> Em caso de término ou rescisão do relacionamento entre o projeto SmartSalt e o Cliente, ambas as partes devem devolver ou destruir todos os dados recebidos, bem como quaisquer cópias ou registros feitos desses dados, a menos que a legislação aplicável exija a sua retenção.</Typography>
<Typography><Box sx={{ fontWeight: 'medium', m: 1 }}>Vigência</Box></Typography>
<Typography><Box component="span" sx={{ fontWeight: 'medium', m: 1 }}>5.1.</Box> Este Termo de Sigilo de Dados entra em vigor na data da sua assinatura pelas partes e permanece válido durante o período em que as partes compartilharem os dados ou até ser rescindido por escrito por ambas as partes.</Typography>
<Typography><Box sx={{ fontWeight: 'medium', m: 1 }}>Disposições Gerais</Box></Typography>
<Typography><Box component="span" sx={{ fontWeight: 'medium', m: 1 }}>6.1.</Box> Qualquer alteração ou modificação deste Termo deve ser feita por escrito e assinada por ambos. </Typography>    
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenUseTerms(false)}>Rejeitar</Button>
          <Button onClick={() => {setAcceptUseterms(true);setOpenUseTerms(false)}}>Aceitar</Button>
        </DialogActions>           
          </Dialog>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>     

        <Stack spacing={1} marginBottom={2}>
        <TextField
            fullWidth
            autoComplete="fantasy_name"          
            label="Nome da Salina"
            {...getFieldProps('fantasy_name')}
            error={Boolean(touched.fantasy_name && errors.fantasy_name)}
            helperText={touched.fantasy_name && errors.fantasy_name}
          />
           <TextFieldInput id="cnpj" variant='outlined' label="CNPJ" name="cnpj" formik={formik} /> 
           <TextFieldInput id="owner" variant='outlined' label="Proprietario" name="owner" formik={formik} /> 
           <TextFieldInput id="phone" variant='outlined' label="Telefone" name="phone" formik={formik} /> 
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Endereço de email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
                <FormControlLabel
                onChange={(e,value) => setOpenUseTerms(value)}
                  control={
                    <Checkbox value="allowExtraEmails" color="primary" />
                  }
                  label="Aceitar os Termos de Uso"
                />
        </Stack>

        <Button fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}       
        >
          Cadastrar
        </Button>
      </Form>
      
    </FormikProvider>
  )
}
