import { Autocomplete, Box, createTheme, Grid, TextField, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import {  
  MenuItem, 
  IconButton, 
} from "@mui/material";
import * as Yup from 'yup';
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import BusinessIcon from "@mui/icons-material/Business";
import BadgeIcon from "@mui/icons-material/Badge";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import ImageIcon from "@mui/icons-material/Image";
import { Modal } from 'react-bootstrap';
import ReactPhoneInput from 'react-phone-input-material-ui';
import CNPJInput from '../Inputs/CNPJInput';
import { api } from '../../services/api';
import TextFieldInput from '../Inputs/TextFieldInput';
import DescriptionIcon from "@mui/icons-material/Description";
import MapIcon from "@mui/icons-material/Map";
import HvacIcon from "@mui/icons-material/Hvac";
import AddLocationIcon from '@mui/icons-material/AddLocation';
import { getCategories } from '../../services/categories';
import Coordinates from '../Coordinates';

export default function FormStation({id, saltern, saltern_id, dataStation, show, handleClose, submit, submitEdit }) {
  const [category, setCategory] = useState({name: ''});
  const [categories, setCategories] = useState();
  const [showMap, setShowMap] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [temporaryLatitude, setTemporaryLatitude] = useState("");
  const [temporaryLongitude, setTemporaryLongitude] = useState("");
  const [data, setData] = useState({
    name: "",
    area: "",
    category_id: "",
    latitude: "",
    longitude: "",
    saltern_id: null,
  });
  const theme = createTheme({
    palette: {
      primary: {
        main: "#F85F2F",
        light: "#F85F2F",
        dark: "#F85F2F",
      },
      secondary: {
        main: "#FFD700",
      },
      tertiary: {
        main: "#008000",
      },
    },
  });
    const formik = useFormik({
        initialValues: {
            name: "",
            area: "",
            category_id: "",
            latitude: "",
            longitude: "",            
        }, 
        validationSchema: Yup.object({
          name: Yup.string().required('Campo Obrigatório'),
          area: Yup.string().required('Campo Obrigatório'),        
          latitude: Yup.string().required('Campo Obrigatório'),
          longitude: !id && Yup.string().required('Campo Obrigatório'),
        }),
        onSubmit: (values) => {
          formik.setSubmitting(true);
          formik.values.category_id = category.id
          formik.values.saltern_id = saltern_id
          try {
            if (!id){
              api
              .post("/stations", formik.values)
              .then((res) => {
                const tempStation = res.data;
                tempStation.category = category;
                submit(tempStation);
              })
              .catch(
                (err) => {
                  console.error(err);

                }
              );
              formik.setSubmitting(false);
            }else{
              api
          .put("/stations/" +id, formik.values)
          .then((res) => {
            submitEdit(formik.values);
          })
          .catch(
            (err) => {
              console.error(err);
            }
          );
            formik.setSubmitting(false);
            }
          //request(values);
        } catch (err) {
          // error()
          console.log(err);
        }
        },
      });
    const clearModal = () => {
        handleClose(!show)
      }
      const openMap = () => {
        setShowMap(true);
        if(id){
          formik.setFieldValue('latitude',dataStation.latitude);
          formik.setFieldValue('longitude', dataStation.longitude);
        }else{
          formik.setFieldValue('latitude',saltern.latitude);
          formik.setFieldValue('longitude', saltern.longitude);
        }
      }

      useEffect(() => {
        if(!id){
          setCategory(undefined);
          formik.resetForm();
        }
      }, [show]);

      useEffect(() => {
        if(id){
          formik.resetForm();     

          formik.setValues(dataStation);
        }
      }, [id])

      useEffect(() => {
        (async () => {
          const responseCategories = await getCategories();
          if(id){                       
            setCategory(responseCategories.find(item => item.id === dataStation.category_id));
          }
          setCategories(responseCategories);
        })();
      }, [data, saltern_id]);

      function getCategoryOptions() {
        return (
          categories 
            ? categories.map((category) => {
                return (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                )
              })
            : null
        )
      }

      function handle(e) {
        const newdata = { ...data };
        newdata[e.target.id] = e.target.value;
        setData(newdata);
      }
    return (
      <>
        <Modal show={show} onHide={() => clearModal()}>
        <Modal.Header closeButton>
          <Modal.Title className="laranja">{id?'Editar':'Adicionar'} Estação de Monitoramento</Modal.Title>
        </Modal.Header>
        <div className="container">
          <br />
          <form onSubmit={formik.handleSubmit}>
            <ThemeProvider theme={theme}>
              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid paddingTop={1}>
                  <AccountBoxIcon style={{ fontSize: "45px" }} color="primary" />
                  &nbsp; &nbsp;
                </Grid>
                <Grid style={{ width: "40%" }}>
                <TextFieldInput autoFocus id="name" label="Nome da Estação" name="name" formik={formik} />                  
                </Grid>
              </Grid>

              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid paddingTop={1}>
                  <DescriptionIcon style={{ fontSize: "45px" }} color="primary" />
                  &nbsp; &nbsp;
                </Grid>
                <Grid style={{ width: "40%" }}>                
                  <Autocomplete                         
                id="type_station_autocomplete"
                options={categories}        
                getOptionLabel={(option) => option ? option?.name : ''}  
                //key={category}
                value={category}
                onChange={(e, value) => setCategory(value)}
                renderInput={(params) => <TextField autocomplete="off" {...params} label="Tipo de Estação" variant='standard'  />}
              />      
                </Grid>
              </Grid>

              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid paddingTop={1}>
                  <MapIcon style={{ fontSize: "45px" }} color="primary" />
                  &nbsp; &nbsp;
                </Grid>
                <Grid style={{ width: "40%" }}>
                <TextFieldInput autoFocus id="area" label="Área Territorial (em m²)" name="area" formik={formik} />                                   
                </Grid>
              </Grid>

              <Grid container 
                direction="row" 
                alignItems="center" 
                justifyContent="center"
                paddingTop={1}
                paddingBottom={3}
              >
                <Grid paddingTop={1}>
                  <HvacIcon style={{fontSize: "45px"}} color="primary"/>
                </Grid>
                <Grid style={{width: "30%"}}>
                <TextFieldInput autoFocus id="latitude" label="Latitude" name="latitude" formik={formik} />                                    
                </Grid>&nbsp;

                <Grid paddingTop={1}>
                  <HvacIcon 
                    style={{
                        fontSize: "45px", 
                        transform: "rotate(90deg)"
                      }} 
                    color="primary"/>
                </Grid>
                <Grid style={{width: "30%"}}>
                <TextFieldInput autoFocus id="longitude" label="Longitude" name="longitude" formik={formik} />                  


                
                </Grid>&nbsp;

                <Grid paddingTop={1}>
                  <IconButton className="btn"
                    onClick={() => openMap()}
                    style={{
                      backgroundColor: "#F85F2F",
                      color: "white"
                    }}>
                    <AddLocationIcon />
                  </IconButton>
                </Grid>

              </Grid>
            </ThemeProvider>

            <Modal.Footer>
              <button
                className="btn"
                style={{
                  backgroundColor: "#F85F2F",
                  color: "white",
                }}
              >
               {id?'Editar':'Adicionar'}
              </button>
            </Modal.Footer>
          </form>
        </div>
      </Modal>
            <Modal 
            size="lg" style={{ backgroundColor: "gray" }} show={showMap} onHide={() => setShowMap(false)}>
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: "30px" }} className="laranja">
                Selecione a Localização da Salina
              </Modal.Title>
            </Modal.Header>
              <Grid padding={1}>
                <Coordinates 
                  className="map" 
                  latitude={formik.values.latitude}
                  setLatitude={(value) => formik.setFieldValue('latitude', value)}
                  longitude={formik.values.longitude}
                  setLongitude={(value) => formik.setFieldValue('longitude', value)} 
                  //coordinates={saltern}
                />
              </Grid>
              <Modal.Footer>
                <button className="btn"
                onClick={() => setShowMap(false)}
                  style={{
                    backgroundColor: "#F85F2F",
                    color: "white"
                  }}>
                  Selecionar
                </button>
              </Modal.Footer>
          </Modal>
          </>
    )
}
