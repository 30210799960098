import React from "react";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import { Box, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import Reports from "../../components/Reports/Reports";
import DefaultNavBar from "../../components/DefaultNavBar";

const ReportsPage = () => {
  const {idCompany, idSaltern} = useParams();
  const { nameCompany, nameSaltern } = useParams();

  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <Box>
      {
        user["tipo"] !== "employees" ?
          <NavBar 
            idCompany={idCompany} 
            nameCompany={nameCompany} 
            idSaltern={idSaltern} 
            nameSaltern={nameSaltern} 
          />
        : <DefaultNavBar  
            idSaltern={idSaltern} 
            nameSaltern={nameSaltern}
          />
      }
      
      <Stack direction="row" spacing={1} justify="space-between">
        <SideBar 
          idCompany={idCompany} 
          nameCompany={nameCompany} 
          idSaltern={idSaltern} 
          nameSaltern={nameSaltern}
        />
        <Reports saltern_id={idSaltern} />
      </Stack>
    </Box>
  );
};

export default ReportsPage;
