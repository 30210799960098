import * as Yup from 'yup';
import { useState, useContext } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel, Button, FormLabel, Typography } from '@mui/material';
import { AuthContext } from '../../contexts/auth';

export default function ForgotPasswordForm() {
    const navigate = useNavigate();
  const {login} = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('O Email precisa ser valido').required('Email é obrigatorio'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      formik.setSubmitting(true);   
      login(formik.values.email, formik.values.password).catch((reason) => {
        console.error(reason);
        setError('E-mail e/ou senha inválidos!')
        //setLoading(false);
    })
       
      //await login(formik.values.email, formik.values.password);
      formik.setSubmitting(false);      
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
      {
                            error 
                            ? <FormLabel style={{ color: "red" }}>
                                {error}
                                <br/>
                                <br/>
                              </FormLabel> 
                            : null
      }

        <Stack spacing={3} mb={4}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Endereço de email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
        </Stack>

        <Button fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}       
        >
          Continuar
        </Button>
      </Form>
    </FormikProvider>
  )
}
