import React, {useState, useEffect, createContext} from "react";

import { useNavigate } from "react-router-dom";

import {api, createSession} from "../services/api";

export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const navigate = useNavigate();
    const [authenticated, setAuthenticated] = useState(true);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const recoveredToken = localStorage.getItem("token");
        const recoveredUser = localStorage.getItem("user");

        if(recoveredUser && recoveredToken) {
            setUser(JSON.parse(recoveredUser))
            api.defaults.headers.Authorization = `Bearer ${recoveredToken}`;
        }

        setLoading(false);
    }, []);
    
    const login = async (email, password) => {
        const response = await createSession(email, password)

        const token = response.data.token;
        const loggedUser = response.data.user;

        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(loggedUser));

        api.defaults.headers.Authorization = `Bearer ${token}`;

        setUser(loggedUser);
        selectRoute(loggedUser);
    };
    const verifyAuth = async () => {
        const recoveredToken = localStorage.getItem("token");
        if(!recoveredToken){
          setAuthenticated(false);
        }
    }
    const selectRoute = (user) => {
        if (user.tipo === "admins") {
            navigate("/");
        } else if (user.tipo === "companies") {
            navigate(`/saltern/${user.id}/${user.name}`);
        } else if (user.tipo === "employees") {
            api
                .get("/members/" + user.id)
                .then((res) => {
                    navigate(`/stations/${user.id}/${user.company}/${res.data?.saltern[0].id}/${res.data?.saltern[0].name}`);
                })
        }
    }

    const logout = () => {

        localStorage.removeItem("token");
        localStorage.removeItem("user");

        api.defaults.headers.Authorization = null;

        setUser(null);
        navigate("/login");
    };

    return (
        <AuthContext.Provider value={{authenticated: !!user, user, loading, login, logout, verifyAuth}}>
            {children}
        </AuthContext.Provider>
    )
}