import React from 'react';
import ThemeProvider from './theme';
import AppRoutes from './AppRoutes';

function App() {
  return (
    <div className="app">
      <ThemeProvider>
      <AppRoutes />
      </ThemeProvider>
    </div>
  );
}

export default App;
