import React, { useState, useEffect } from "react";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  MenuItem
} from '@mui/material';

import { Spinner } from "react-bootstrap";
import { api } from "../../services/api";
import Chart from './Chart';

const CardChart = (props) => {

  const { saltern_id } = props;

  const [stations, setStations] = useState([]);
  const [type_measurements, setType_measurements] = useState(undefined)
  const [selectedType_measurements, setSelectedType_measurements] = useState(undefined)
  const [properties, setProperties] = useState([])
  const [empty, setEmpty] = useState(true);
  const [loading, setLoading] = useState(true);

  async function fetchStationsData() {
    await api
      .get("/stations?saltern_id=" + saltern_id)
      .then(res => {
          setStations(res.data);
          if(res.data.length > 0) {
            setEmpty(false);
          }
          setLoading(false);
      })
  }

  async function fetchTypeData() {
    await api
      .get("/typemeasurement?category_id=1")
      .then(res => {
          setProperties(res.data);
          setLoading(false);
      })
  }

  async function fetchPropertiesData() {
    await api
      .get("/properties?type_measurements_id=1")
      .then(res => {
          setProperties(res.data);
          setLoading(false);
      })
  }

  useEffect(() => {
    fetchPropertiesData();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    fetchTypeData();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    fetchStationsData();
    // eslint-disable-next-line
  }, [])

  function getStationOptions() {
    return stations
    .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
    .map((station) => {
      return (
        <MenuItem key={station.id} value={station.id}>
          {station.name}
        </MenuItem>
      )
    })
  }


  function getTypeOptions() {
    return type_measurements
    ?.map((type) => {
      return (
        <MenuItem key={type.id} value={type.id.toString()}>
          {type.name}
        </MenuItem>
      )
    })
  }
  function getPropertyOptions() {
    return properties
    .map((property) => {
      return (
        <MenuItem key={property.id} value={property.id.toString()}>
          {property.name}
        </MenuItem>
      )
    })
  }

  return (
    <Box sx={{ minWidth: 275 }}>
      <Card>
        <CardHeader 
          style={{ backgroundColor: "#F85F2F", color: "white" }}
          title="Mensurações por Estação"
        />
        <CardContent>
          {
            loading 
              ? <Spinner animation="border" role="status" variant="primary">
                  <span className="visually-hidden">Carregando...</span>
                </Spinner>
              : empty
                ? <p>Nenhuma estação cadastrada.</p>
                : <Chart 
                saltern_id={saltern_id} 
                    stations={stations} 
                    getTypeOptions={getTypeOptions} 
                    getStationOptions={getStationOptions} 
                    getPropertyOptions={getPropertyOptions}
                    firstValue={stations}
                  />
          }
        </CardContent>
      </Card>
    </Box>
  );

}

export default CardChart;
