import React, { createContext, useState, useContext } from 'react';

// Crie o contexto
const MedicoesContext = createContext();

// Crie um provedor de contexto que irá envolver o componente pai
const MedicoesProvider = ({ children }) => {
  // Defina o estado inicial para as medições
  const [medicoes, setMedicoes] = useState([]);

  // Função para adicionar uma nova medição ao estado
  const adicionarMedicao = (novaMedicao) => {
    setMedicoes([...medicoes, novaMedicao]);
  };

    // Função para adicionar uma nova medição ao estado
    const setarMedicao = (medicoesVar) => {       
        setMedicoes(medicoesVar);
      };

      const atualizarMedicao = (novaMedicao) => {
      const tempMeasurements = [...medicoes];
      const index = medicoes.findIndex((object) => object.id === novaMedicao.id);
      tempMeasurements[index] = novaMedicao;
        setMedicoes(tempMeasurements);
      };

  // Função para limpar todas as medições
  const limparMedicoes = () => {
    setMedicoes([]);
  };

  return (
    <MedicoesContext.Provider
      value={{
        medicoes,
        adicionarMedicao,
        atualizarMedicao,
        setarMedicao,
        limparMedicoes,
      }}
    >
      {children}
    </MedicoesContext.Provider>
  );
};

// Crie um utilitário de contexto personalizado para simplificar o uso
const useMedicoes = () => {
  const context = useContext(MedicoesContext);
  if (!context) {
    throw new Error('useMedicoes deve ser usado dentro de um MedicoesProvider');
  }
  return context;
};

export { MedicoesProvider, useMedicoes };
