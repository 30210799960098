import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { 
  Alert, 
  Box, 
  Button, 
  Fab, 
  Grid, 
  IconButton, 
  MenuItem, 
  Snackbar, 
  Tooltip, 
  TextField, 
} from "@mui/material";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CheckIcon from "@mui/icons-material/Check";
import HistoryIcon from "@mui/icons-material/History";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DescriptionIcon from "@mui/icons-material/Description";
import MapIcon from "@mui/icons-material/Map";
import HvacIcon from "@mui/icons-material/Hvac";
import AddLocationIcon from '@mui/icons-material/AddLocation';

import { Modal } from "react-bootstrap";
import moment from "moment";

import Mapa from "./Mapa";
import Measurements from "./Measurements";
import Coordinates from "../Coordinates";

import { api } from "../../services/api";
import { getCategories } from "../../services/categories";
import { getMeasurements } from "../../services/measurements";
import { getStations } from "../../services/stations";
import { searchSaltern } from "../../services/salterns";
import FormStation from "./FormStation";
import { useMedicoes } from '../../contexts/medicoesContext';

export default function Stations(props) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#F85F2F",
        light: "#F85F2F",
        dark: "#F85F2F",
      },
      secondary: {
        main: "#FFD700",
      },
      tertiary: {
        main: "#008000",
      },
    },
  });
  const { medicoes, adicionarMedicao, setarMedicao, limparMedicoes } = useMedicoes();
  const [id, setId] = useState(undefined);
  const { idSaltern } = useParams();
  const [show, setShow] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showStation, setShowStation] = useState(false);
  const [showMeasurement, setShowMeasurement] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [status, setStatus] = useState();

  const handleShow = () => setShow(true);
  const handleShowEdit = () => setShowEdit(true);
  const handleShowStation = () => setShowStation(true);
  const handleCloseStation = () => setShowStation(false);
  const handleCloseMeasurement = () => setShowMeasurement(false);
  const handleChangeShowMeasurement = (value) => {console.log('enter');setShowMeasurement(value);}



  const handleClose = () => {
    setId(undefined)
    //setCategory(undefined);
    setShow(false);   
  };

  const handleCloseEdit = () => {
    setShowEdit(false);
    setData({
      name: "",
      area: "",
      category_id: "",
      latitude: "",
      longitude: "",
      saltern_id: idSaltern,
    });
    setCategory("");
    setLatitude("");
    setLongitude("");
  };

  const openMap = () => {
    setShowMap(true);
    setTemporaryLatitude(latitude);
    setTemporaryLongitude(longitude);
  }

  const closeMap = () => {
    setLatitude(temporaryLatitude);
    setLongitude(temporaryLongitude);
    setShowMap(false);
  }

  const submitMap = (e) => {
    e.preventDefault();
    setShowMap(false);
  }

  const [typeMeasurements, setTypeMeasurements] = useState(undefined)
  const [stations, setStations] = useState([]);
  const [measurements, setMeasurements] = useState();
  const [categories, setCategories] = useState();
  const [saltern, setSaltern] = useState();
  const [category, setCategory] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [temporaryLatitude, setTemporaryLatitude] = useState("");
  const [temporaryLongitude, setTemporaryLongitude] = useState("");
  const [selectStation, setSelectStation] = useState(0);
  const [data, setData] = useState({
    name: "",
    area: "",
    category_id: "",
    latitude: "",
    longitude: "",
    saltern_id: idSaltern,
  });

  useEffect(() => {
    (async () => {
      const response = await getStations(idSaltern);
      setStations(response);
      const responseSaltern = await searchSaltern(idSaltern);
      setSaltern(responseSaltern);
      const responseCategories = await getCategories();
      setCategories(responseCategories);
    })();
  }, [data, idSaltern]);

  useEffect(() => {
    if(showStation){
      api.get("/typemeasurement?category_id="+selectStation.category_id)
      .then(result => {      
        setTypeMeasurements(result.data);      
      });
    }
  },[showStation])

  function getCategoryOptions() {
    return (
      categories 
        ? categories.map((category) => {
            return (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            )
          })
        : null
    )
  }

  function submit(newStation) {
        const mydata = [...stations, newStation];
        setStations(mydata);
        handleClose();
  }

  function submitEdit(editedData) {
    let tempStations = stations.filter((item) => item.id !== id);
    tempStations = [...tempStations, editedData];
    setStations(tempStations);
    setSelectStation(editedData);
    handleClose();
  }

  function remove(id) {
    if (window.confirm("Tem certeza de que deseja excluir a estação de monitoramento selecionada?")) {
      api
        .delete("/stations/" + id)
        .then((res) => {
          const myalldata = stations.filter((item) => item.id !== id);
          setStations(myalldata);
          setStatus("success");
          setOpenSnackBar(true);
          handleCloseStation();
        })
        .catch(
          (err) => {
            console.error(err);
            setStatus("error");
            setOpenSnackBar(true);
          }
        );
    }
  }

  function handle(e) {
    if(e){
    const tempData = stations;
    tempData.push(e)
    ////console.log(tempData);
    setData(tempData);
    }
    handleClose();
  }
  function update(item) {
    setId(item.id);
    setShow(true);
    setCategory(item.category_id);
    setLatitude(item.latitude);
    setLongitude(item.longitude);
    setData(item);    
  }

  async function modalStation(item) {
    setSelectStation(item);

    const response = await getMeasurements(item.id);
    setMeasurements(response.data);
    setarMedicao(response.data);
    handleShowStation();
  }

  const handleCloseSnackBar = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  let contador = 0;

  const display = medicoes
    ?.sort((a, b) => new Date(b.collected_start) - new Date(a.collected_start))
    .filter((item) => item.collected_start >= moment().subtract(7, "d").format("YYYY-MM-DD") + "T00:00:00.000Z")
    .map((item) => {
      contador++;
      let str = item.collected_start.split("T")[0];
      let date = moment(str);
      let dateComponent = date.format("LL");

      return (
        <div key={item.id}>
          <hr />
          <div>
            <span className="float-start">{ item.property.name}</span>
            <span className="float-end">{dateComponent}</span>
          </div>
          <br />
          <br />
          <div>
            <h6 className="float-start">{item.value} {item.property.unit}</h6>
            <ThemeProvider theme={theme}>
              {item.value > 6 ? (
                <span className="float-end">
                  <ReportProblemIcon color="secondary" />
                </span>
              ) : (
                <span className="float-end">
                  <CheckIcon color="tertiary" />
                </span>
              )}
            </ThemeProvider>
          </div>
          <br />
        </div>
      ) 
    });

  return (
    <Box flex={6} p={2}>
      <FormStation show={show} id={id} submit={(value) => submit(value)} submitEdit={submitEdit} handleClose={handleClose} saltern_id={idSaltern} saltern={saltern} dataStation={data}/>
      <Modal show={showStation} onHide={handleCloseStation}>
        <Modal.Header closeButton>
          <Modal.Title>Estação de Monitoramento</Modal.Title>
        </Modal.Header>
        <div className="container">
          <div className="container">
            <br />
            <p>
              <span className="laranja">Nome da Estação: </span>
              {selectStation.name}
            </p>
            <p>
              <span className="laranja">Área Territorial: </span>
              {selectStation.area}
            </p>
            <p>
              <span className="laranja">Tipo: </span>
              {
                categories
                  ?.filter((category) => category.id === selectStation.category_id)
                  .map((category) => {
                    return category.name
                  })
              }
            </p>
            <p>
              <span className="laranja">Localização: </span>
              {selectStation.latitude}, {selectStation.longitude}
            </p>

            <ThemeProvider theme={theme}>
              <Grid className="text-center">
                <Button onClick={() => setShowMeasurement(true)}>
                  <HistoryIcon theme={theme} color="primary" />
                </Button>
                {showMeasurement ? (
                  <Measurements
                    station={selectStation}
                    salternId={idSaltern}
                    categories={categories}
                    show={setShowMeasurement}
                    onHide={handleCloseMeasurement}
                    handleChangeShowMeasurement={handleChangeShowMeasurement}
                  />
                ) : null}

                <Button onClick={() => update(selectStation)}>
                  <EditIcon theme={theme} color="primary" />
                </Button>

                <Button onClick={() => remove(selectStation.id)}>
                  <DeleteIcon theme={theme} color="primary" />
                </Button>
              </Grid>
            </ThemeProvider>
          </div>
          <hr />
          <div>
            <Modal.Title>
              <h4>Últimas Leituras</h4>
            </Modal.Title>
            <div className="container-fluid">
              {contador !== 0 ? (
                display
              ) : (
                <div>
                  <hr />
                  <br />
                  <p className="text-center">Nenhuma leitura cadastrada nos últimos 7 dias.</p>
                </div>
              )}
              <br />
            </div>
          </div>
        </div>
      </Modal>

      <Mapa 
        className="map" 
        stations={stations} 
        modalStation={modalStation} 
        saltern_id={idSaltern}
      />

      <Box className="float-end">
        <Box sx={{ "& > :not(style)": { m: 1 }, position: "fixed", bottom: 25, right: 25 }}>
          <ThemeProvider theme={theme}>
            <Tooltip title="Adicionar Estação" color="primary" onClick={handleShow}>
              <Fab theme={theme} color="primary" aria-label="add">
                <AddIcon />
              </Fab>
            </Tooltip>
          </ThemeProvider>
        </Box>
      </Box>

      <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
        <Alert onClose={handleCloseSnackBar} variant="filled" severity={status} sx={{ width: '100%' }}>
          {
            status === "success" ? 
              <span>Operação realizada com sucesso!</span>
            : status === "error" ?
              <span>Erro!</span>
            : null
          }
        </Alert>
      </Snackbar>

    </Box>
  );
}
