import React, { useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { Spinner } from "react-bootstrap";
import AirIcon from '@mui/icons-material/Air';
import { searchSaltern } from "../../services/salterns";
import "../../styles/map.css";
 
const Mapa = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyA9J4pl06UlM8nHuE_6Hd7teVs_eWMx4ZQ",
  });

  const { stations, modalStation, saltern_id } = props;
  const [map, setMap] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saltern, setSaltern] = useState({ latitude: 0, longitude: 0 });

  useEffect(() => {
    (async () => {
      const response = await searchSaltern(saltern_id);
      setSaltern(response);
    })();

    let markers = stations?.map((item, index) => {
      return (
        <Marker
          key={index}
          onClick={() => modalStation(item)}
          position={{
            lat: parseFloat(item.latitude),
            lng: parseFloat(item.longitude),
          }}
          options={{       
            icon: item.category.name === 'Evaporador'? require('./marker-wind.png') : item.category.name === 'Cristalizador'?  require('./marker-tank.png'):  require('./marker-salt.png'),     
            label: {
              text: item.name,
              className: "map-marker",
            },
          }}
        />
      );
    });
    setMap(
      <GoogleMap
        //mapTypeId="satellite"
        options={{streetViewControl: false,   mapTypeId: "satellite",  mapTypeControlOptions: {         
          mapTypeIds: [
            "satellite"
          ]
      }}}
        mapContainerStyle={{ width: "100%", height: "80vh" }}
        center={{
          lat: parseFloat(saltern?.latitude),
          lng: parseFloat(saltern?.longitude),
        }}
        zoom={15}
      >
        {markers}
      </GoogleMap>
    );

    setLoading(false);
  }, [modalStation, saltern?.latitude, saltern?.longitude, saltern_id, stations]);

  return <div className="map">{
    isLoaded 
      ? loading 
        ? <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Carregando...</span>
          </Spinner>
        : map 
      : <></>
  }</div>;
};

export default Mapa;
