import React, { useEffect, useState } from "react";

import {
  Grid, 
  TextField, 
  Portal, 
  Snackbar, 
  Alert, 
  Autocomplete
} from "@mui/material";
import * as Yup from 'yup';
import { ThemeProvider } from "@mui/material/styles";
import TodayIcon from '@mui/icons-material/Today';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import DescriptionIcon from "@mui/icons-material/Description";
import { Modal } from "react-bootstrap";

import theme from "../../configs/theme";
import { getProperties } from "../../services/properties";
import { addMeasurements, editMeasurements } from "../../services/measurements";
import { useFormik } from "formik";
import { api } from "../../services/api";

const MeasurementsModal = (props) => {
  
  const [typeOfMeasurements,setTypeOfMeasurements] = useState(undefined);

  const [selectedTypeOfMeasurements, setSelectedTypeOfMeasurements] = useState(undefined)

  const [properties, setProperties] = useState(undefined);
  const [selectedProperties, setSelectedProperties] = useState(undefined)
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [status, setStatus] = useState();

  const formik = useFormik({
    initialValues: {
      type_measurements_id: '',
      value: '',
      collected_start: '',
      collected_end: '',
    },
    validationSchema: Yup.object({
      type_measurements_id: Yup.number().required('Campo Obrigatório'),
      value: Yup.number().required('Campo Obrigatório'),
      collected_start: Yup.string().required('Campo Obrigatório'),
      collected_end: selectedTypeOfMeasurements?.hasEndDate&& Yup.string().required('Campo Obrigatório'),
    }),
    onSubmit: (values) => {
      //formik.values.type_measurements_id = selectedTypeOfMeasurements.id
      formik.setSubmitting(true);
    }
  });

  const submit = async (e) => {
    e.preventDefault();
    props.setShow(false);
    if(!props.dataEditForm){   
    formik.values.type_measurements_id = selectedTypeOfMeasurements.id;
    formik.values.property_id = selectedProperties.id; 
    const data_req = { ...formik.values, station_id: props.stationId };

    
    const result = await addMeasurements(data_req);
    result.data.property= properties.find(e => e.id === result.data.property_id)
    props.changeMeasurements(result.data);

    if (result.status === 200) {
      setStatus("success");
    } else {
      setStatus("error");
    }
    setOpenSnackBar(true);
  }else{
    props.setDataEditForm(null)        
    const result = await editMeasurements(formik.values);
    props.changeMeasurements(formik.values);

    if (result.status === 200) {
      setStatus("success");
    } else {
      setStatus("error");
    }
     setOpenSnackBar(true);
  }
  };

  useEffect(() => {
    formik.resetForm();
    setSelectedTypeOfMeasurements(null);
    setSelectedProperties(null);
  }, [props.show])

  useEffect(() => {
    if(props.dataEditForm){
      props.dataEditForm.collected_start = props.dataEditForm.collected_start? props.dataEditForm.collected_start.split("T")[0] : props.dataEditForm.collected_start;
      //props.dataEditForm.collected_end = props.dataEditForm.collected_end ? props.dataEditForm.collected_end.split(" ")[0] : props.dataEditForm.collected_end;
      formik.setValues(props.dataEditForm);
      /*console.log(properties)
      console.log(props.dataEditForm)
      var typeTemp = typeOfMeasurements.find(tp => tp.id === props.dataEditForm.type_measurements_id);
      console.log(typeTemp);
      setSelectedTypeOfMeasurements(typeTemp);
      var typeProp = properties.find(pp => pp.id === props.dataEditForm.property_id);
      //typeProp.collected_start = typeProp.collected_start ? typeProp.collected_start.split(" ")[0] : typeProp.collected_start;
      //typeProp.collected_end = typeProp.collected_end ? typeProp.collected_end.split(" ")[0] : typeProp.collected_end;
      setSelectedProperties(typeProp);
      console.log(typeProp);*/
    }else{
      formik.resetForm();
    }
  },[props.dataEditForm])



  useEffect(() => {
    if(props.show){
    if(props.categoryId){
      api.get("/typemeasurement?category_id="+props.categoryId)
      .then(result => {      
        setTypeOfMeasurements(result.data);
        if(props.dataEditForm){
          var typeTemp = result.data.find(tp => tp.id === props.dataEditForm.type_measurements_id);
          setSelectedTypeOfMeasurements(typeTemp);
        }
      });
    }
  }
  },[props.categoryId, props.show])

  useEffect(() => {
    if(props.show && selectedTypeOfMeasurements){
    (async () => {
      const response = await getProperties(selectedTypeOfMeasurements.id);
      setProperties(response);
      if(props.dataEditForm){
        var typeProp = response.find(pp => pp.id === props.dataEditForm.property_id);      
        setSelectedProperties(typeProp);
      }
    })();
  }
  }, [props.show,selectedTypeOfMeasurements]);

  const handleCloseSnackBar = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  return (
    <Grid>
      <Modal style={{ backgroundColor: "gray" }} show={props.show} onHide={() => props.setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Formulário de Leituras</Modal.Title>
        </Modal.Header>

        <div className="container">
          <br />
          <form onSubmit={(e) => submit(e)}>
            <ThemeProvider theme={theme}>
            <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid paddingTop={1}>
                  <DescriptionIcon style={{ fontSize: "45px" }} color="primary" />
                  &nbsp; &nbsp;
                </Grid>
                <Grid style={{ width: "40%" }}>
                <Autocomplete
                    id="tags-standard"
                    value={selectedTypeOfMeasurements}
                    defaultValue={selectedTypeOfMeasurements}
                    onChange={(event, newValue) => {
                        setSelectedTypeOfMeasurements(newValue);
                      }}
                    options={typeOfMeasurements}
                    getOptionLabel={(type) => type.name}                   
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Tipos de mensuração"
                        placeholder="Tipos de mensuração"
                    />
                    )}
                />
                </Grid>
              </Grid> 
              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid paddingTop={1}>
                  <DescriptionIcon style={{ fontSize: "45px" }} color="primary" />
                  &nbsp; &nbsp;
                </Grid>
                <Grid style={{ width: "40%" }}>
                <Autocomplete
                    id="propriedade"
                    value={selectedProperties}
                    onChange={(event, newValue) => {
                        setSelectedProperties(newValue);
                      }}
                    disabled={!selectedTypeOfMeasurements}
                    options={properties}
                    getOptionLabel={(propertie) => propertie.name? propertie.name : ''}                    
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Propriedade"
                        placeholder="Propriedade"
                    />
                    )}
                    />                 
                </Grid>
              </Grid>             
              <Grid container direction="row" alignItems="center" justifyContent="center">              
              <Grid paddingTop={1}>
                  <HistoryEduIcon style={{ fontSize: "45px" }} color="primary" />
                  &nbsp; &nbsp;
                </Grid>
                <Grid style={{ width: "40%" }}>
                  
                  <TextField
                    fullWidth
                    label={'Valor'}
                    InputLabelProps={{ required: false }}
                    value={formik.values['value']}
                    disabled={!selectedTypeOfMeasurements}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik ? formik.touched['value'] && formik.errors['value'] : null} // dois argumentos ta disparando o erro nos fomularios, o "error" aceita um tipo boleano e não string
                    helperText={formik ?
                        (formik.touched['value'] && formik.errors['value']) : null
                    }
                    type="number"
                    name={'value'}
                    id={'value'}
                    variant="standard"                    
                  />
                </Grid>
              </Grid>
              {selectedTypeOfMeasurements?.name && 
              <div>
              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid paddingTop={1}>
                  <TodayIcon style={{ fontSize: "45px" }} color="primary" />
                  &nbsp; &nbsp;
                </Grid>
                <Grid style={{ width: "40%" }}>
                  <TextField
                    label={selectedTypeOfMeasurements?.hasEndDate? "Data inicial de coleta": "Data de coleta"}
                    InputLabelProps={{ required: false }}        
                    disabled={!selectedTypeOfMeasurements}           
                    value={formik.values['collected_start']}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik ? formik.touched['collected_start'] && formik.errors['collected_start'] : null} // dois argumentos ta disparando o erro nos fomularios, o "error" aceita um tipo boleano e não string
                    helperText={formik ?
                        (formik.touched['collected_start'] && formik.errors['collected_start']) : null
                    }
                    type="date"
                    name="collected_start"
                    id="collected_start"
                    className="form-control"
                    variant="standard"
                    required
                  />
                </Grid>
              </Grid>
              {selectedTypeOfMeasurements?.hasEndDate && 
              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid paddingTop={1}>
                  <InsertInvitationIcon style={{ fontSize: "45px" }} color="primary" />
                  &nbsp; &nbsp;
                </Grid>
                <Grid style={{ width: "40%" }}>
                  <TextField
                    label="Data final de coleta"
                    InputLabelProps={{ required: false }}    
                    disabled={!selectedTypeOfMeasurements}               
                    value={formik.values['collected_end']}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik ? formik.touched['collected_end'] && formik.errors['collected_end'] : null} // dois argumentos ta disparando o erro nos fomularios, o "error" aceita um tipo boleano e não string
                    helperText={formik ?
                        (formik.touched['collected_end'] && formik.errors['collected_end']) : null
                    }
                    type="date"
                    name="collected_end"
                    id="collected_end"
                    className="form-control"
                    variant="standard"
                    required
                  />
                </Grid>
              </Grid>
              }
              </div>
              }
              <br />
            </ThemeProvider>

            <Modal.Footer>
              <button
                className="btn"
                style={{
                  backgroundColor: "#F85F2F",
                  color: "white",
                }}
              >
                {props.dataEditForm?'Editar' :'Adicionar'}
              </button>
            </Modal.Footer>
          </form>
        </div>
      </Modal>

      <Portal>
        <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
          <Alert onClose={handleCloseSnackBar} variant="filled" severity={status} sx={{ width: '100%' }}>
            {
              status === "success" ? 
                <span>Operação realizada com sucesso!</span>
              : status === "error" ?
                <span>Erro!</span>
              : null
            }
          </Alert>
        </Snackbar>
      </Portal>
    </Grid>
  );
};

export default MeasurementsModal;
