import { TextField } from '@mui/material';
import React from 'react'
import ReactInputMask from 'react-input-mask';

export default function CNPJInput({id, label,variant, name, formik}) {
  return (
    <ReactInputMask
    mask="99.999.999/9999-99"            
    maskChar=""
    id={id}
    placeholder={label}
    label={label}            
    fullWidth
    value={formik.values.cnpj}
    variant={ variant? variant :"standard"}
    onChange={(e) => {
      formik.setFieldValue(name, e.target.value);
      /*const value = e.target.value.replaceAll('/', '').replaceAll(' ','').replaceAll('.','').replaceAll('-','');
      if(value.length === 14){
        formik.setFieldValue(name, value);
      }*/
    }}
    onBlur={formik.handleBlur}            
  >
    {() => <TextField  variant="standard" placeholder={label} label={label} error={!!(formik.touched[name] && formik.errors[name])}
    helperText={
      formik.touched[name] &&
      formik.errors[name]
    }/>}
  </ReactInputMask> 
  )
}
