import { api } from "./api";

export async function getReports(saltern_id) {
  return await api
    .get("/reports?saltern_id=" + saltern_id)
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.error(err));
}

export async function filterReports(reports) {
  return await reports.sort((a, b) => a.name.localeCompare(b.name));
}

export async function filterReportsByDate(measurements, startDate, endDate) {
  if (measurements) {
    return await measurements.map((item) => {
      const dateItem = new Date(item.collected_start);
      if (dateItem.inRange(dateItem, startDate, endDate)) {
        return item;
      }
      return [];
    });
  } else {
    return null;
  }
}

export async function averageReportsByStation(measurements) {
  if (measurements) {
    let stations = [];

    //find a station in measurements and remove it from measurements
    while (measurements.length > 0) {
      const station = {
        name: measurements[0].name,
        property_id: measurements[0].property_id,
        valueAccumulated: 0,
        valueCount: 0,
        valueAverage: 0,
      };
      measurements = measurements.filter((item) => {
        if (item.name === station.name) {
          station.valueAccumulated += item.value;
          station.valueCount++;
          return false;
        } else {
          return true;
        }
      });
      stations.push(station);
    }
    return stations;
  } else {
    return null;
  }
}
