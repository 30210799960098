import { api } from "./api";

export async function getCategories() {
  return await api
    .get("/categories")
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.error(err));
}
