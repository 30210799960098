import React, { useEffect, useState } from "react";

import Chart from "react-apexcharts";
import { Spinner } from "react-bootstrap";
import { Autocomplete, Grid, MenuItem, TextField } from "@mui/material";
import moment from "moment";
import { api } from "../../services/api";
import { filterReports, getReports } from "../../services/reports";

const EvolutionChart = (props) => {
    const [empty, setEmpty] = useState(true);
    const [loading, setLoading] = useState(true);
    const [station, setStation] = useState(undefined);
    const [stations, setStations] = useState([]);  
    const [stationsSelected, setStationsSelected] = useState(undefined);    
    const [properties, setProperties] = useState(undefined)
    const [measurements, setMeasurements] = useState([]);
    const date = new Date();

    const [dataFinal, setDataFinal] = useState(date.toLocaleDateString("en-CA"));
    var now = moment();
    const [dataInicial, setDataInicial] = useState(
      now.subtract(30, "days").format("YYYY-MM-DD")
    );
    const { saltern_id } = props;
    const { evolutionReports } = props;
    const [property, setProperty] = useState(undefined);



    const [measurementValue,setMeasurementValue] = useState([])
    const [measurementDate, setMeasurementDate] = useState([])
    
    const [measurementValue2,setMeasurementValue2] = useState([])
    const [measurementDate2, setMeasurementDate2] = useState([])

    const [measurementValue3,setMeasurementValue3] = useState([])
    const [measurementDate3, setMeasurementDate3] = useState([])


      async function fetchPropertiesData() {
        await api
          .get("/properties?type_measurements_id=1")
          .then(res => {
              setProperties(res.data);
              setLoading(false);
          })
      }

      useEffect(() => {
        //fetchStationsData();
        fetchPropertiesData();
        // eslint-disable-next-line
      }, [])


      
      useEffect(() => {        
        (async () => {
            let { measurements } = await getReports(localStorage.getItem('saltern_id'));
            measurements = await filterReports(measurements);
            setMeasurements(measurements);
            await api
            .get("/stations?saltern_id=" + localStorage.getItem('saltern_id'))
            .then(res => {
              function calcMean(arrayMeasurements, stationItem){
                const count = arrayMeasurements.filter(e => e.name === stationItem.name);
                const average = count.reduce((total, next) => total + parseInt(next.value), 0)/ (count.length || 1);
                stationItem.media = parseFloat(average).toFixed(2);
                return stationItem;
              }
              const tempStations = res.data.map(item => calcMean(measurements, item));
                setStations(tempStations);
            })
             //let { evolutionReports } = await getReports(localStorage.getItem('saltern_id'));
            // evolutionReports = await averageReportsByStation(evolutionReports);
            // setEvolutionReports(evolutionReports);
          })();
      }, [props.stations]);

      function getStationOptions() {        
        return stations
        .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
        .map((station) => {
          return (
            <MenuItem key={station.id} value={station.id}>
              {station.name}
            </MenuItem>
          )
        })
      }

      function setValuesToChar(){
        const tempMean = [];
        const tempName = [];
        const measurementsFiltered = measurements?.filter(e => e.property_id === property.id);

        function calcMean(arrayMeasurements, stationItem){
            const count = arrayMeasurements.filter(e => e.name === stationItem.name);
            const average = count.reduce((total, next) => total + parseInt(next.value), 0)/ (count.length || 1);
            stationItem.media = parseFloat(average).toFixed(2);
            return stationItem;
          }
        const tempStations = stationsSelected.map(item => calcMean(measurementsFiltered, item));
        tempStations.map((station) => { tempMean.push(parseFloat(station.media)); tempName.push(station.name); });
            setMeasurementValue(tempMean);
            setMeasurementDate(tempName);

      };

      useEffect(() => {
        if(stations){   
            if(property && stationsSelected)       
            setValuesToChar();
        }
      },[property, stationsSelected]);
    return (
        <span>
            <br />
            <span>
            <br />
            <span style={{display: "flex", justifyContent: "flex-start"}}>
            <Grid container spacing={2} display={'flex'} alignItems="center" justifyContent="center">            
            <Grid item xs={3}>
            <Autocomplete
                    id="propriedade-standard"
                    clearText='Limpar'
                    closeText='Fechar'
                    openText='Abrir'
                    value={property}
                    onChange={(event, newValue) => {
                        setProperty(newValue);
                      }}
                    options={properties}
                    getOptionLabel={(property) => property.name}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        //variant="standard"
                        label="Propriedade"
                        placeholder="Propriedade"
                    />
                    )}
                />
                </Grid>
                <Grid item xs={3}>
                <Autocomplete
                    multiple
                    id="tags-standard"
                    clearText='Limpar'
                    closeText='Fechar'
                    openText='Abrir'
                    value={stationsSelected}
                    limitTags={2}
                    onChange={(event, newValue) => {
                        setStationsSelected(newValue);
                      }}
                    options={stations}
                    getOptionLabel={(station) => station.name}
                    defaultValue={[]}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        //variant="standard"
                        label="Estações"
                        placeholder="Estações"
                    />
                    )}
                />
                </Grid>
                <Grid item xs={3}>
                <TextField
                label="Data Inicial"
                InputLabelProps={{ shrink: true, required: true }}
                onChange={(e) => setDataInicial(e.target.value)}
                value={dataInicial}
                type="date"
                name="data_inicial"
                id="data_inicial"
                className="form-control"             
              />
              </Grid>
              <Grid item xs={3}>
              <TextField
                label="Data Final"
                InputLabelProps={{ shrink: true, required: true }}
                onChange={(e) => setDataFinal(e.target.value)}
                value={dataFinal}
                type="date"
                name="data_final"
                id="data_final"
                className="form-control"
              />
              </Grid>
            </Grid>
            </span>

            <br/><hr/><br/>
            {
            loading
              ? <Spinner animation="border" role="status" variant="primary">
                  <span className="visually-hidden">Carregando...</span>
                </Spinner>
              :
              <>

                    <Chart
                        type="line"
                        width="100%"
                        height={500}

                        series={[
                            {
                                name: ""+ property && property?.name,
                                data: measurementValue,
                                enabled: false
                            }
                        ]}

                        options={{
                            title: { 
                                text: ""+property?property?.name:'Gráfico de valor médio de propriedade',
                                style: {fontSize: 20}
                            },
                            colors: ["#15C5C0"],
                            theme: {mode: "light"},

                            xaxis: {
                                tickPlacement: "on",
                                categories: measurementDate,
                                title: { 
                                    text: "Data da Coleta",
                                    style: {color: "#f90000", fontSize: 15}
                                }
                            },

                            markers: {
                                size: 4,
                            },

                            responsive: [{
                                breakpoint: 1000,
                                yaxis: {
                                    categories: measurementDate
                                },
                                options: {
                                    plotOptions: {
                                    line: {
                                        horizontal: true
                                    }
                                    }
                                }
                            }],

                            fill: {
                                type: 'gradient',
                                gradient: {
                                    shade: 'dark',
                                    
                                    shadeIntensity: 0.5,
                                    
                                    inverseColors: true,
                                    opacityFrom: 1,
                                    opacityTo: 1,
                                    stops: [0, 50, 100],
                                    colorStops: []
                                }
                            },

                            yaxis: {
                                labels: {
                                    formatter: (val)=>{return `${val}`},
                                    style: {
                                        fontSize: "15"
                                    }
                                },
                                title: {
                                    text: `Valor da Mensuração (em ${property ? property?.unit:''})`,
                                    style: { color: "#f90000", fontSize: 15 }
                                }
                            },

                            legend: {
                                position: 'top',
                                horizontalAlign: 'center',
                                floating: true,
                                offsetY: -25,
                                offsetX: -5
                            }
                        }}
                    />
            </>
        }
        </span>
        </span>
    )
}

export default EvolutionChart;