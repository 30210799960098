import { TextField } from '@mui/material'
import React from 'react'

export default function TextFieldInput({ defaultValue, variant, autoFocus, id, label, name, type, formik}) {
  return (
    <TextField
    autoFocus={autoFocus}        
    id={id}
    label={label}
    type={ type || 'text'}
    fullWidth
    variant={ variant? variant :"standard"}
    defaultValue={defaultValue}
    value={defaultValue ? '': formik.values[name]}
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    error={formik ? formik.touched[name] && formik.errors[name] : null} // dois argumentos ta disparando o erro nos fomularios, o "error" aceita um tipo boleano e não string
    helperText={formik ?
        (formik.touched[name] && formik.errors[name]) : null
    }
  /> 
  )
}
