import { api } from "./api";

export async function getProperties(type_measurements_id) {
  if(type_measurements_id){
  return await api
    .get("/properties/"+type_measurements_id)
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.error(err)); 
  }else{
    return await api
    .get("/properties")
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.error(err));
  }
}
