import React, { useContext } from "react";

import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import SignUpPage from "./pages/SignUpPage";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import SalternsPage from "./pages/SalternsPage";
import MembersPage from "./pages/MembersPage";
import StationsPage from "./pages/StationsPage";
import ReportsPage from "./pages/ReportsPage";
import ConfigsPage from "./pages/ConfigsPage";
import { AuthProvider, AuthContext } from "./contexts/auth";
import { MedicoesProvider } from "./contexts/medicoesContext";
import ForgotPasswordPage from "./pages/ForgotPassword";
import ResetPasswordPage from "./pages/ResetPassword";

const AppRoutes = () => {
  const Private = ({ children }) => {
    const { authenticated, loading } = useContext(AuthContext);

    if (loading) {
      return <div className="loading">Carregando...</div>;
    }

    if (!authenticated) {
      return <Navigate to="/login" />;
    }
    return children;
  };

  return (
    <Router>
      <MedicoesProvider>
      <AuthProvider>
        <Routes>
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/register" element={<SignUpPage />} />
          <Route exact path="/resetpassword" element={<ResetPasswordPage />} />
          <Route exact path="/forgotpassword" element={<ForgotPasswordPage />} />
          <Route
            exact
            path="/"
            element={
              <Private>
                <HomePage />
              </Private>
            }
          />
          <Route
            exact
            path="/configs"
            element={
              <Private>
                <ConfigsPage />
              </Private>
            }
          />
          <Route
            exact
            path="/saltern/:idCompany/:nameCompany"
            element={
              <Private>
                <SalternsPage />
              </Private>
            }
          />
          <Route
            exact
            path="/stations/:idCompany/:nameCompany/:idSaltern/:nameSaltern"
            element={
              <Private>
                <StationsPage />
              </Private>
            }
          />
          <Route
            exact
            path="/members/:idCompany/:nameCompany/:idSaltern/:nameSaltern"
            element={
              <Private>
                <MembersPage />
              </Private>
            }
          />
          <Route
            exact
            path="/reports/:idCompany/:nameCompany/:idSaltern/:nameSaltern"
            element={
              <Private>
                <ReportsPage />
              </Private>
            }
          />
        </Routes>
      </AuthProvider>
      </MedicoesProvider>
    </Router>
  );
};

export default AppRoutes;
