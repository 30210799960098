import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ThunderstormOutlinedIcon from "@mui/icons-material/ThunderstormOutlined";
import ThermostatOutlinedIcon from "@mui/icons-material/ThermostatOutlined";
import WindPowerOutlinedIcon from "@mui/icons-material/WindPowerOutlined";

import { useEffect, useState } from "react";
import { getWeather } from "../services/weather";

export default function Weather() {
  const [weather, setWeather] = useState({
    wind: "",
    temp: "",
    clouds: "",
  });

  useEffect(() => {
    (async () => {
      const temp = await getWeather(-5.343766, -36.123604);
      setWeather(temp);
    })();
  }, []);

  return (
    <Box flex={1} p={2} sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
          marginTop: "40%",
          paddingLeft: "5%",
        }}
      >
        <ListItem sx={{ color: "#F85F2F" }}>
          <ListItemAvatar>
            <Avatar>
              <ThunderstormOutlinedIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Chuva" secondary={weather.clouds + " %"} />
        </ListItem>
        <ListItem sx={{ color: "#F85F2F" }}>
          <ListItemAvatar>
            <Avatar>
              <ThermostatOutlinedIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Temperatura" secondary={weather.temp + " °C"} />
        </ListItem>
        <ListItem sx={{ color: "#F85F2F" }}>
          <ListItemAvatar>
            <Avatar>
              <WindPowerOutlinedIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Vento" secondary={weather.wind + " m/s"} />
        </ListItem>
      </List>
    </Box>
  );
}
