import React, { useState, useEffect } from "react";

import moment from "moment";
import "moment/locale/pt-br";

import Chart from "react-apexcharts";

import { api } from "../../services/api";
import { Autocomplete, Grid, MenuItem, TextField } from "@mui/material";
import { getProperties } from "../../services/properties";
import TextFieldInput from "../Inputs/TextFieldInput";

const LineChart = (props) => {
 
    var now = moment();
    var final = now.format("YYYY-MM-DD");
    var init = now.subtract(30, "days").format("YYYY-MM-DD");

    const {getStationOptions, getPropertyOptions, getTypeOptions, saltern_id, firstValue} = props;
    const [property, setProperty] = useState(undefined);

    function organizeOptions() {
      return firstValue
      .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
    }
  
    const sortedOptions = organizeOptions();
    const json = JSON.stringify(sortedOptions[0].id);
    const initialStation = json.replace(/"/g, "");


    const [stations, setStations] = useState(undefined);
    const [station, setStation] = useState(undefined);
    const [dataInicial, setDataInicial] = useState(init);
    const [dataFinal, setDataFinal] = useState(final);

    const [type_measurements, setType_measurements] = useState(undefined)
    const [selectedType_measurements, setSelectedType_measurements] = useState(undefined)
    const [properties, setProperties] = useState([])
    const [selectedPropertie, setSelectedPropertie] = useState(undefined)

    const [station2, setStation2] = useState(initialStation);
    const [dataInicial2, setDataInicial2] = useState(init);
    const [dataFinal2, setDataFinal2] = useState(final);

    const [station3, setStation3] = useState(initialStation);
    const [dataInicial3, setDataInicial3] = useState(init);
    const [dataFinal3, setDataFinal3] = useState(final);

    const [measurementValue, setMeasurementValue] = useState([]);
    const [measurementDate, setMeasurementDate] = useState([]);

    const [measurementValue2, setMeasurementValue2] = useState([]);
    const [measurementDate2, setMeasurementDate2] = useState([]);

    const [measurementValue3, setMeasurementValue3] = useState([]);
    const [measurementDate3, setMeasurementDate3] = useState([]);


    Array.prototype.max = function() {
      return Math.max.apply(null, this);
    };
    
    function ordenarEstacoes(a, b) {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      }
      // a must be equal to b
      return 0;
    }

    async function fetchStationsData() {
      await api
        .get("/stations?saltern_id=" + saltern_id)
        .then(res => {
          const tempStations = res.data.sort((a, b) => (a.name > b.name) - (a.name < b.name))
            setStations(tempStations);
        })
      }
    
        useEffect(() => {
          fetchStationsData();
          // eslint-disable-next-line
        }, [])

    useEffect(() => {
        const measId = [];
        const measVal = [];
        const measDat = [];

        const getMeasurements = async() => {
            await api
            .get("/measurements?station_id=" + station.id)
            .then(res => {
                const response = res.data
                    .sort((a, b) => new Date(a.collected_start) - new Date(b.collected_start))
                    .filter((item) => item.property_id === property.id &&
                    item.collected_start >= (
                      ( moment(dataInicial3).format("YYYY-MM-DD") ) + "T00:00:00.000Z"
                    ) &&
                    item.collected_start <= (
                      ( moment(dataFinal3).format("YYYY-MM-DD") ) + "T00:00:00.000Z"
                    )
                  );
                return response;
            })
            .then(response => {
                response.filter((element) => {
                    const isDuplicate = measId.includes(element.id);
                    measVal.includes(element.value);
                    measDat.includes(element.collected_start);
                    
                    if(!isDuplicate) {
                        measId.push(element.id);
                        measVal.push(element.value);
                        measDat.push(moment(element.collected_start).zone('+0300').format("DD/MM/YYYY"));

                        return true;
                    }

                    return false;
                })
            })
            setMeasurementValue3(measVal);
            setMeasurementDate3(measDat);
        // eslint-disable-next-line
        }
        if(property && station)
          getMeasurements();

    }, [station, property,  dataInicial3, dataFinal3])

    useEffect(() => {
      if(station){
        api.get("/typemeasurement?category_id="+station.category_id)
        .then(result => {      
          setType_measurements(result.data);
        });
      }
    },[station])

    useEffect(() => {
      if(selectedType_measurements){
      (async () => {
        const response = await getProperties(selectedType_measurements.id);
        setProperties(response);
      })();
    }
    }, [selectedType_measurements]);

    return (
      <span>
        <br />
        <span style={{display: "flex", justifyContent: "flex-start"}}>
        <Grid container direction="row" spacing={4} marginX={1}>
        <Grid item xs={4}>
        <Autocomplete
                    id="station_autocomplete"
                    value={station}
                    clearText='Limpar'
                    closeText='Fechar'
                    openText='Abrir'
                    //defaultValue={selectedTypeOfMeasurements}
                    onChange={(event, newValue) => {
                        setStation(newValue);
                        setSelectedType_measurements(null);
                        setProperty(null);
                      }}
                    options={stations}
                    getOptionLabel={(type) => type.name}                   
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Estação"
                        placeholder="Estação"
                    />
                    )}
                />     
            </Grid> 
            {station &&   
            <Grid item xs={4}>           
            <Autocomplete
                    id="type_autocomplete"
                    clearText='Limpar'
                    closeText='Fechar'
                    openText='Abrir'
                    value={selectedType_measurements}
                    //defaultValue={undefined}
                    onChange={(event, newValue) => {
                        setSelectedType_measurements(newValue);
                        setProperty(null);
                      }}
                    options={type_measurements}
                    getOptionLabel={(type) => type.name}                   
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Tipo de Mensuração"
                        placeholder="Tipo de Mensuração"
                    />
                    )}
                />     
                </Grid>
            }
                {(station && selectedType_measurements) &&
                <Grid item xs={4}>                
            <Autocomplete
                    id="propertie_autocomplete"
                    clearText='Limpar'
                    closeText='Fechar'
                    openText='Abrir'
                    value={property}
                    //defaultValue={undefined}
                    onChange={(event, newValue) => {
                        setProperty(newValue);
                      }}
                    options={properties}
                    getOptionLabel={(type) => type.name}                   
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Propriedade"
                        placeholder="Propriedade"
                    />
                    )}
                />     
                </Grid>
              }
          </Grid>
        </span>

        <br/><hr/><br/>


            <span>
              <span style={{display: "flex", justifyContent: "flex-start"}}>               
              <Grid container alignItems="center" justifyContent="space-around">
                <Grid item>            
                  <TextField
                    label="Data Inicial"
                    InputLabelProps={{ shrink: true, required: true }}
                    onChange={(e) => setDataInicial3(e.target.value)}
                    value={dataInicial3}
                    type="date"
                    name="data_inicial3"
                    id="data_inicial3"
                    className="form-control"
                  />
                </Grid>

                <Grid item>
                  <TextField
                    label="Data Final"
                    InputLabelProps={{ shrink: true, required: true }}
                    type="date"
                    onChange={(e) => setDataFinal3(e.target.value)}
                    value={dataFinal3}                    
                    name="data_final3"
                    id="data_final3"
                  />
                </Grid>
                </Grid>
              </span>

              <br/>

              <Chart
                type="line"
                width="100%"
                height={500}

                series={[
                    {
                        name: ""+(property?property?.name:''),
                        data: measurementValue3
                    }
                ]}

                options={{
                  title: { 
                      text: ""+(property?property?.name:'Gráfico Por Propriedade'),
                      style: {fontSize: 20}
                  },
                  colors: ["#E94A1B"],
                  theme: {mode: "light"},

                  xaxis: {
                      tickPlacement: "on",
                      categories: measurementDate3,
                      title: { 
                          text: "Data da Coleta",
                          style: {color: "#f90000", fontSize: 15}
                      }
                  },

                  markers: {
                      size: 4,
                  },

                  responsive: [{
                      breakpoint: 1000,
                      yaxis: {
                        categories: measurementDate3
                      },
                      options: {
                        plotOptions: {
                          line: {
                            horizontal: true
                          }
                        }
                      }
                  }],

                  fill: {
                      type: 'gradient',
                      gradient: {
                        shade: 'dark',
                        
                        shadeIntensity: 0.5,
                        
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 50, 100],
                        colorStops: []
                      }
                  },

                  yaxis: {
                    tickAmount: Math.trunc(measurementValue3.max()/2),
                    max: measurementValue3.max()*2,
                    min: 0,
                      labels: {
                          formatter: (val)=>{return `${parseFloat(val).toFixed(2)}`},
                          style: {
                              fontSize: "15"
                          }
                      },
                      title: {
                          text: `Valor da Mensuração (em ${property?property?.unit:''})`,
                          style: { color: "#f90000", fontSize: 15 }
                      }
                  },

                  legend: {
                      position: 'top',
                      horizontalAlign: 'center',
                      floating: true,
                      offsetY: -25,
                      offsetX: -5
                  }
                }}
              />
            </span> 
        
      </span>
    )
}

export default LineChart;