import React, { useState } from 'react'
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
  } from '@mui/material';
import TableHeadComponent from './TableHeadComponent';
import MoreMenu from './MoreMenu';
export default function TableComponent({tableHead, data, removeFunction, editFunction, navFunction}) {
 

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');
  
  
    const [orderBy, setOrderBy] = useState('name');
  
    const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const params = {
    onClick: (row) => {
      if(navFunction)
      navFunction(data[row.currentTarget.id.split('-')[1]]);
      //console.log(row)
      //setOpenDetailsDialog(true);
      //setDataCompanyView(filteredCompanies[row.currentTarget.id.split('-')[1]]);
    },
    style: { cursor: navFunction ? 'pointer': 'auto' }
  };
    return (
    <div>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHeadComponent
                  order={order}
                  orderBy={orderBy}
                  headLabel={tableHead}
                  rowCount={data.length}
                  
                  onRequestSort={handleRequestSort}                  
                />
                <TableBody>
                  {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const { id } = row;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                      >
                                               
                        {tableHead.map( (column, i) => ( <TableCell align="center" padding="none" key={`cell${i}-${index}`} id={`cell${i}-${index}`}
                          {...params}
                          style={{ cursor: navFunction ? 'pointer': 'auto' }}
                        >
                          {Array.isArray(column.id)? column.id.map(v => row[v]? row[v]+', ': '') : row[column.id]}
                        </TableCell>))
                        }

                        <TableCell align="right"
                          key={'action'+row}
                          onClick={() => {

                          }}>
                          <MoreMenu deleteFunction={removeFunction} editFunction={editFunction} id={row} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

          <TablePagination
            labelRowsPerPage="Itens por página"
            // getItemAriaLabel ={(type) => { return `Ir para ${type === 'first'? 'primeira' : type === 'last'? 'ultima': type === 'next'?  'proxima': 'anterior'} pagina`; }}
            labelDisplayedRows={({ from, to, count }) => `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
    </div>
  )
}
