import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';

import { Grid, Table, TableBody, TableCell, Button, Paper, TableContainer, TableHead, TableRow, TablePagination, Tab } from '@mui/material';
import SnackbarComponent from "../../components/GeneralElements/Snackbar";
import { api } from "../../services/api";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";

export default function Configs(props) {


  const [valueTab, setValueTab] = useState("1");

  const [parameters, setParameters] = useState([]);

  const [fishVentures, setFishVentures] = useState([]);

  const [openDialogParameter, setOpenDialogParameter] = useState(false)  
  
  const [editIdParameter, setEditIdParameter] = useState(null)

  const [dataEditParameter, setDataEditParameter] = useState({})

  const [openDialogFishVenture, setOpenDialogFishVenture] = useState(false)  
  
  const [editIdFishVenture, setEditIdFishVenture] = useState(null)

  const [dataEditFishVenture, setDataEditFishVenture] = useState({})

  const [messageSnack, setMessageSnack] = useState("")

  const [openSnack, setOpenSnack] = useState(false)

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteParameter = (id) => {
     api.delete(`/properties/${id}`)
   .then(result => {
     const tempParameters = parameters.filter((item) => item.id !== id );
     setParameters(tempParameters);  
     setMessageSnack("Deletado com sucesso");
     setOpenSnack(true);
   }); 
 };

 const openEditModal = (id) => {   
  setOpenDialogParameter(true)
   setEditIdParameter(id);
   setDataEditParameter(parameters.filter(item => item.id === id)[0]) 
 };

 const editParameter = (parameterEdited) => {
   setOpenDialogParameter(!openDialogParameter);
   setMessageSnack("Edição efetuada com sucesso");
   setOpenSnack(true);
   const tempParameters = parameters;    
   const indexEdit = tempParameters.findIndex(item => item.id === parameterEdited.id);

   tempParameters[indexEdit] = parameterEdited;

   setParameters(tempParameters);
 };

 const saveParameter = (parameterSaved) => {
  const tempParameters = parameters;
  tempParameters.push(parameterSaved);
  setParameters(tempParameters);
  setOpenDialogParameter(!openDialogParameter);
   setMessageSnack("Cadastro efetuado com sucesso");
   setOpenSnack(true);
   
 };

 const deleteFishVenture = (id) => {
  api.delete(`/fishventures/${id}`)
.then(result => {
  const tempFishventures = fishVentures.filter((item) => item.id !== id );
  setParameters(tempFishventures);  
  setMessageSnack("Deletado com sucesso");
  setOpenSnack(true);
}); 
};

const openEditModalFishVenture = (id) => {   
setOpenDialogFishVenture(true)
setEditIdFishVenture(id);
setDataEditFishVenture(fishVentures.filter(item => item.id === id)[0]) 
};

const editFishVenture = (fishVentureEdited) => {
setOpenDialogFishVenture(!openDialogFishVenture);
setMessageSnack("Edição efetuada com sucesso");
setOpenSnack(true);
const tempFishVentures = fishVentures;    
const indexEdit = tempFishVentures.findIndex(item => item.id === fishVentureEdited.id);

tempFishVentures[indexEdit] = fishVentureEdited;

setFishVentures(tempFishVentures);
};

const saveFishVenture = (fishVentureSaved) => {
const tempFishVentures = fishVentures;
tempFishVentures.push(fishVentureSaved);
setFishVentures(tempFishVentures);
setOpenDialogFishVenture(!openDialogFishVenture);
setMessageSnack("Cadastro efetuado com sucesso");
setOpenSnack(true);

};

 useEffect(() => {
  api.get("/properties/")
    .then(result => {      
      setParameters(result.data)
    });
    api.get("/fishventures/")
    .then(result => {      
      setFishVentures(result.data)
    });
}, []);

  return (
    <Box flex={6} p={2}>     
 <div>
       <TabContext value={valueTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(e, value) => setValueTab(`${value}`)} aria-label="lab API tabs example">
            <Tab label="Parametros" value="1" />         
            <Tab label="Empreendimentos" value="2" />         
          </TabList>
        </Box>
        <TabPanel value="1">
        <Grid container marginBottom={4} spacing={1}>
        <Grid item xs={8} />
        <Grid item display="flex" alignItems="center"
  justifyContent ="end" xs={4}>
        <Button onClick={() => {
            setEditIdParameter(null);
            setDataEditParameter(null);
            setOpenDialogParameter(!openDialogParameter)
          }} variant="contained" to="#">
          Novo parametro
        </Button>
        </Grid>
        </Grid>        
        <TableContainer component={Paper}>                
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome do Parametro</TableCell>
              <TableCell>Unidade de Medida</TableCell>   
            </TableRow>
          </TableHead>
          <TableBody>
            {parameters.map((row, index) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >              
                <TableCell component="th" scope="row" id={`cellDC1-${index}`}
                        >
                {row.name}
                </TableCell>
                <TableCell id={`cellDC2-${index}`}
                     >{row.unit}</TableCell>        
                <TableCell align="right">
                  {/*<UserMoreMenu deleteFunction={deleteParameter} editFunction={openEditModal} id={row.id}/>*/
}
                </TableCell>        
              </TableRow>
                ))}
              </TableBody>
            </Table>
        </TableContainer>
        { /* <ModalCompany id={editIdParameter} crudName={'Parâmetro'} openDialog={openDialogParameter} handleDialog={() => setOpenDialogParameter(!openDialogParameter)}>
            <FormProperty handleSubmitSave={saveParameter} handleSubmitEdit={editParameter} id={editIdParameter} dataEdit={dataEditParameter}/>
        </ModalCompany>     
            <Sucess message = {messageSnack} snackbarType="sucess" open={openSnack} handleClose={() => setOpenSnack(false)} />*/}
        </TabPanel>     
        <TabPanel value="2">
        <Grid container marginBottom={4} spacing={1}>
        <Grid item xs={8} />
        <Grid item display="flex" alignItems="center"
  justifyContent ="end" xs={4}>
        <Button onClick={() => {
            setEditIdFishVenture(null);
            setDataEditFishVenture(null);
            setOpenDialogFishVenture(!openDialogFishVenture)
          }} variant="contained" to="#" >
          Novo empreendimento
        </Button>
        </Grid>
        </Grid>        
        <TableContainer component={Paper}>                
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Razão Social</TableCell>
              <TableCell>Categoria</TableCell>   
            </TableRow>
          </TableHead>
          <TableBody>
            {fishVentures.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow
                key={row.rz_social}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >              
                <TableCell component="th" scope="row" id={`cellDC1-${index}`}
                        >
                {row.rz_social}
                </TableCell>
                <TableCell id={`cellDC2-${index}`}
                     >{row.categoria}</TableCell>        
                <TableCell align="right">
                 {/*} <UserMoreMenu deleteFunction={deleteFishVenture} editFunction={openEditModalFishVenture} id={row.id}/>*/}
                </TableCell>        
              </TableRow>
                ))}
              </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            labelRowsPerPage="Itens por página"
            // getItemAriaLabel ={(type) => { return `Ir para ${type === 'first'? 'primeira' : type === 'last'? 'ultima': type === 'next'?  'proxima': 'anterior'} pagina`; }}
            labelDisplayedRows={({ from, to, count }) => `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={fishVentures.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        {/*<ModalCompany id={editIdFishVenture} crudName={'Empreendimento da Rota do Pescado'} openDialog={openDialogFishVenture} handleDialog={() => setOpenDialogFishVenture(!openDialogFishVenture)}>
            <FormFishVenture handleSubmitSave={saveFishVenture} handleSubmitEdit={editFishVenture} id={editIdFishVenture} dataEdit={dataEditFishVenture}/>
        </ModalCompany>     
            <Sucess message = {messageSnack} snackbarType="sucess" open={openSnack} handleClose={() => setOpenSnack(false)} />*/}
        </TabPanel>     
      </TabContext>
    </div>
    </Box>
  );
}
