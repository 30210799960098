import axios from "axios";
import { useContext } from "react";
import { AuthContext } from '../contexts/auth';

export const api = axios.create({
  baseURL: "https://api.smartsalt.com.br",
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

api.interceptors.response.use(
  (response) => {
      return response
  },
  async (error) => {
      //var originalConfig = error.config

      if (error.response) {
        console.error(error.response);
        if (
          error.response.data.message ===
              'E_INVALID_JWT_TOKEN: invalid signature'
      ) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      }
      }
      return Promise.reject(error)
  }
)

export const createSession = async (email, password) => {
  return api.post("/sessions", { email, password });
};
