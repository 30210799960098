import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Fab,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import PreviewIcon from "@mui/icons-material/Preview";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Spinner } from "react-bootstrap";
import "../../styles/companies.css";

import { api } from "../../services/api";
import getCompanies from "../../services/companies";
import SnackbarComponent from "../../components/GeneralElements/Snackbar";
import FormCompanie from "./FormCompanie";
import TableComponent from "../Table/TableComponent";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F85F2F",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'fantasy_name', label: 'Nome Fantasia', alignRight: false },
  { id: 'cnpj', label: 'CNPJ', alignRight: false },
  { id: 'owner', label: 'Dono', alignRight: false },
  { id: 'phone', label: 'Telefone', alignRight: false },
];
export default function Companies(props) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#F85F2F",
        light: "#F85F2F",
        dark: "#F85F2F",
      },
    },
  });

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [id, setId] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [status, setStatus] = useState();

  const handleShow = () => {
    setId(null);    
    setShow(true);
  }
    
  const handleShowEdit  = () =>  setShow(true);

  const handleClose = () => {
    setShow(false);
  };

  const [companies, setCompanies] = useState([]);
  const [data, setData] = useState({
    name: "",
    fantasy_name: "",
    cnpj: "",
    owner: "",
    phone: "",
    email: "",
    password: "",
    logo: "",
  });

  useEffect(() => {
    (async () => {
      const result = await getCompanies();
      setCompanies(result);
      setLoading(false);
    })();
  }, [data]); //data
 

  function remove(id) {
      api
        .delete("/companies/" + id)
        .then((res) => {
          const myalldata = companies.filter((item) => item.id !== id);
          setCompanies(myalldata);
          setStatus("success");
          setOpenSnackBar(true);
        })
        .catch(
          (err) => {
            console.error(err);
            setStatus("error");
            setOpenSnackBar(true);
          }
        );
  }

  function handle(e) {
    if(e){
    const tempData = companies;
    tempData.push(e)
    setData(tempData);
    }
    handleClose();
  }

  function update(item) {
    setId(item.id);
    handleShowEdit();
    setData(item);
  }

  function viewGroup(id, name) {
    navigate("/saltern/" + id + "/" + name);
  }

  const handleCloseSnackBar = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  let contador = 0;

  const display = companies
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
    .map((item) => {
      contador++;

      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="center" sx={{ color: "#F85F2F", fontWeight: "bold" }}>
            {item.name}
          </StyledTableCell>
          <StyledTableCell align="center">{item.fantasy_name}</StyledTableCell>
          <StyledTableCell align="center">{item.cnpj}</StyledTableCell>
          <StyledTableCell align="center">{item.owner}</StyledTableCell>
          <StyledTableCell align="center">{item.phone}</StyledTableCell>
          <ThemeProvider theme={theme}>
            <StyledTableCell align="center" className="text-center">
              <Button onClick={() => viewGroup(item.id, item.name)}>
                <PreviewIcon theme={theme} color="primary" />
              </Button>
              <Button onClick={() => update(item)}>
                <EditIcon theme={theme} color="primary" />
              </Button>
              <Button onClick={() => remove(item.id)}>
                <DeleteIcon theme={theme} color="primary" />
              </Button>
            </StyledTableCell>
          </ThemeProvider>
        </StyledTableRow>
      )
    }
  );

  return (
    <Box flex={6} p={2}>     

    <FormCompanie id={id} dataCompanie={data} show={show} handleClose = {handle} />
    <TableComponent tableHead={TABLE_HEAD} data={companies} removeFunction={id => remove(id)} editFunction={item => update(item)} navFunction={(item) => viewGroup(item.id, item.name)}/>
    { /*
  
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Nome</StyledTableCell>
              <StyledTableCell align="center">Nome Fantasia</StyledTableCell>
              <StyledTableCell align="center">CNPJ</StyledTableCell>
              <StyledTableCell align="center">Proprietário</StyledTableCell>
              <StyledTableCell align="center">Telefone</StyledTableCell>
              <StyledTableCell align="center">E-mail</StyledTableCell> 
              <StyledTableCell align="center">Ações</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              loading ? (
                <TableRow>
                  <TableCell>
                    <Spinner animation="border" role="status" variant="primary">
                      <span className="visually-hidden">Carregando...</span>
                    </Spinner>
                  </TableCell>
                </TableRow>
              ) : (
                contador !== 0 
                  ? display
                  : <TableRow>
                      <TableCell colSpan={7} align="center">
                        Nenhum grupo salineiro cadastrado.
                      </TableCell>
                    </TableRow>
              )
            }
          </TableBody>
        </Table>
          </TableContainer> */}
      <br />
      
      <Box className="float-end">
        <Box sx={{ "& > :not(style)": { m: 1 } }}>
          <ThemeProvider theme={theme}>
            <Tooltip title="Adicionar Grupo" color="primary" onClick={() => handleShow()}>
              <Fab theme={theme} color="primary" aria-label="add">
                <AddIcon />
              </Fab>
            </Tooltip>
          </ThemeProvider>
        </Box>
      </Box>

      <SnackbarComponent openSnackBar={openSnackBar} handleCloseSnackBar={handleCloseSnackBar} status={status}/>

    </Box>
  );
}
