import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import ConfirmAlert from './ConfirmAlert';
import { Delete, Edit, MoreVert } from '@mui/icons-material';
// component

// ----------------------------------------------------------------------

export default function MoreMenu({id, deleteFunction, editFunction}) {  
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const deleteHandleFunction = () => {
      setIsOpenConfirm(false);
      deleteFunction(id.id); 
      setIsOpen(false)
  }
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVert/>

      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }} to="#" onClick={() => setIsOpenConfirm(true)}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText primary="Excluir" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem component={RouterLink} to="#" onClick={() => editFunction(id)} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Edit/>
          </ListItemIcon>
          <ListItemText primary="Editar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <ConfirmAlert open={isOpenConfirm} handleClose={() => setIsOpenConfirm(false)} handleConfirm={deleteHandleFunction} />
      </Menu>
    </>
  );
}
