import React from "react";

import { Card, CardActions, CardContent, Typography } from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../configs/theme";
import { checkMeasurements } from "../../services/measurements";
import moment from "moment";

const CardReport = (props) => {
  const { measurement, properties } = props;

  let property;

  [property] = properties?.filter((item) => {
    return item.id === measurement.property_id;
  });

  const check = checkMeasurements(measurement.property_id, measurement.value);

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Estação: {measurement.name}</Typography>
        <Typography mt={2} variant="subtitle2" gutterBottom>{property?.name}: {measurement.value} {property?.unit}</Typography>
        <Typography variant="body2" gutterBottom>Data de Coleta: {moment.utc(measurement.collected_start).format("DD/MM/YYYY") } {measurement.collected_end&&" a "+moment.utc(measurement.collected_end)}</Typography>
      </CardContent>
    </Card>
  );
};

export default CardReport;
