import React, { useState, useEffect } from "react";
import CardReport from "./CardReport";
import { Card, CardContent, CardActions, Grid, TextField, CardHeader, Pagination } from "@mui/material";
import { getProperties } from "../../services/properties";

import moment from "moment";
import "moment/locale/pt-br";

const CardsReport = (props) => {
  const { measurements } = props;
  const [showMore, setShowMore] = useState(false);
  const [properties, setProperties] = useState();
  const [page, setPage] = useState(1);

  const date = new Date();
  const [dataFinal, setDataFinal] = useState(date.toLocaleDateString("en-CA"));
  var now = moment();
  const [dataInicial, setDataInicial] = useState(
    now.subtract(30, "days").format("YYYY-MM-DD")
  );

  const [Cards, setCards] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await getProperties();
      setProperties(response);
    })();
  }, []);

  useEffect(() => {
    if(measurements){
      let ultimaDataMed = moment(measurements[0].collected_start);
      setDataFinal(ultimaDataMed.format("YYYY-MM-DD"));
      setDataInicial( ultimaDataMed.subtract(30, "days").format("YYYY-MM-DD"))
    }
    (async () => {
      const endDate = new Date(dataFinal);
      const startDate = new Date(dataInicial);
      
      let CardsTemp = await measurements?.filter((element, key) => {
        
        if (!(moment(element.collected_start).isBefore(moment(dataInicial)) || moment(element.collected_start).isAfter(moment(dataFinal)))){
          return true;
        }
        return false;
      });

      //CardsTemp = Cards.filter( card => !(moment(card.collected_start).isBefore(dataInicial) || date.isAfter(dataFinal)))
      setCards(CardsTemp?.map((element, key) =>  <CardReport collected_start={element.collected_start} key={key} measurement={element} properties={properties}></CardReport>));
    })();
  }, [dataFinal, dataInicial, measurements, properties]);

  return (
    <div>
      <Card>
        <CardHeader 
          style={{ backgroundColor: "#F85F2F", color: "white" }}
          title="Últimas Medições"
        />
        <br />
        <CardContent>
           <span style={{ display: "flex", justifyContent: "flex-start" }}>
            <Grid container alignItems="center" justifyContent="center">
              <TextField
                label="Data Inicial"
                InputLabelProps={{ shrink: true, required: true }}
                onChange={(e) => setDataInicial(e.target.value)}
                value={dataInicial}
                type="date"
                name="data_inicial_station_monitoring"
                id="data_inicial_station_monitoring"
                className="form-control"
                size="small"
                sx={{ width: "80%" }}
              />
            </Grid>

            <Grid container alignItems="center" justifyContent="center">
              <TextField
                label="Data Final"
                InputLabelProps={{ shrink: true, required: true }}
                onChange={(e) => setDataFinal(e.target.value)}
                value={dataFinal}
                type="date"
                name="data_final"
                id="data_final"
                className="form-control"
                size="small"
                sx={{ width: "80%" }}
              />
            </Grid>
  </span> 
  </CardContent> 

        {showMore ? Cards : Cards?.filter((x) => x !== null).slice((page-1)*3, (page-1)*3+3)}
        <CardActions>
          <button style={{ color: "blue" }} className="btn" onClick={() => setShowMore(!showMore)}>
            {showMore ? "Mostrar Menos" : "Mostrar Mais"}
          </button>
          <Pagination page={page} onChange={(e, value) => setPage(value)}  count={Math.trunc(Cards?.length/3)} />
        </CardActions>
      </Card>
    </div>
  );
};

export default CardsReport;