import React, { useEffect, useState } from "react";

import {
  Alert, 
  Button, 
  Fab, 
  Grid, 
  Portal, 
  Snackbar 
} from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Modal, Spinner } from "react-bootstrap";

import ExportData from "./ExportData";
import MeasurementsModal from "./MeasurementsModal";
import { deleteMeasurements } from "../../services/measurements";

import moment from "moment";
import "moment/locale/pt-br";
import "../../styles/stations.css";
import { getProperties } from "../../services/properties";
import { useMedicoes } from "../../contexts/medicoesContext";

const Measurements = (props) => {
  const { medicoes, adicionarMedicao, atualizarMedicao, setarMedicao } = useMedicoes();
  const stationId = props.station.id;
  const stationName = props.station.name;
  const salternId = props.salternId;
  const categories = props.categories;

  const [measurements, setMeasurements] = useState(null, stationId, stationName, salternId);
  const [properties, setProperties] = useState();
  const [showExport, setShowExport] = useState(false);
  const [loading, setLoading] = useState(true);
  const [handleFormMeasurements, setHandleFormMeasurements] = useState(false);
  const [handleFormMeasurementsEdit, setHandleFormMeasurementsEdit] = useState(false);
  const [dataEditForm, setDataEditForm] = useState();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [status, setStatus] = useState();

  const updateMeasurement = (item) => {
    setDataEditForm(item);
    setHandleFormMeasurements(true);
  };

  const removeMeasurement = async (item) => {
    if (window.confirm("Tem certeza de que deseja excluir este grupo?")) {
      const index = medicoes.findIndex((object) => object.id === item.id);
      setarMedicao([...medicoes.slice(0, index), ...medicoes.slice(index + 1)]);
      const result = await deleteMeasurements(item.id);

      if (result.status === 200) {
        setStatus("success");
      } else {
        setStatus("error");
      }

      setOpenSnackBar(true);
    }
  };

  const handleCloseExport = () => setShowExport(false);

  moment.locale("pt-br");

  const theme = createTheme({
    palette: {
      primary: {
        main: "#F85F2F",
        light: "#F85F2F",
        dark: "#F85F2F",
      },
      secondary: {
        main: "#FFD700",
      },
      tertiary: {
        main: "#008000",
      },
    },
  });

  

  const handleCloseSnackBar = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };



    function changeMeasurement(medicaoTemp){
      if(dataEditForm){
        atualizarMedicao(medicaoTemp);
      }else{
        adicionarMedicao(medicaoTemp);
      }
      props.handleChangeShowMeasurement(false);
      props.handleChangeShowMeasurement(true);
    }

    useEffect(() => {
      (async () => {       
        const responseProperties = await getProperties(props.station.type_measurements_id);
        setProperties(responseProperties);
        setLoading(false);
      })();
    }, [stationId]);

  return (
    <Grid>
      <Modal show={props.show} onHide={props.onHide} style={{ backgroundColor: "gray" }} size="lg" autoFocus>
        <div className="container-fluid">
          <Modal.Header closeButton>
            <h2 style={{ fontWeight: "bold" }}>Histórico de Mensurações</h2>
          </Modal.Header>

          <div className="container-fluid">
            <br />
            <div className="lastMeasurements">
              <h4 className=" laranja">Estação&nbsp;{stationName}</h4>
              <div className="lastMeasurements-child">
                <Button className="button-export" variant="contained" color="primary" onClick={() => setShowExport(true)}>
                  <FileDownloadIcon />
                  Exportar
                </Button>
                <Fab 
                  size="small" 
                  theme={theme} 
                  variant="contained" 
                  color="primary" 
                  aria-label="add"
                  onClick={() => {setHandleFormMeasurements(true); setDataEditForm(undefined);}}
                >
                  <AddIcon />
                </Fab>
              </div>

              { showExport 
                ? <ExportData 
                    station={props.station} 
                    properties={properties}
                    categories={categories}
                    salternId={salternId}
                    show={setShowExport} 
                    onHide={handleCloseExport} 
                  /> 
                : null
              }
            </div>
            <div className="container-fluid">
              {loading ? (
                <Spinner animation="border" role="status" variant="primary">
                  <span className="visually-hidden">Carregando...</span>
                </Spinner>
              ) : medicoes.lenght !== 0 ? (
                medicoes
    ?.map((item) => {
      let str = item.collected_start.split("T")[0];
      let date = moment(str);
      let dateComponent = date.format("LL");

      return (
        <div key={item.id}>
          <hr />
          <div>
            <span className="float-start">{item.property.name}</span>
            <Button className="float-end" onClick={() => updateMeasurement(item)}>
              <EditIcon theme={theme} color="primary" />
            </Button>
            <span className="float-end">{dateComponent}</span>
          </div>
          <br />
          <br />
          <div>
            <h6 className="float-start">{item.value} {item.property.unit}</h6>

            <Button className="float-end" onClick={async () => removeMeasurement(item)}>
              <DeleteIcon theme={theme} color="primary" />
            </Button>
            <ThemeProvider theme={theme}>
              {item.value > 6 ? (
                <span className="float-end">
                  <ReportProblemIcon color="secondary" />
                </span>
              ) : (
                <span className="float-end">
                  <CheckIcon color="tertiary" />
                </span>
              )}
            </ThemeProvider>
          </div>
          <br />
        </div>
      ) 
    })
              ) : (
                <div>
                  <hr />
                  <br />
                  <p className="text-center">Nenhuma leitura cadastrada.</p>
                </div>
              )}
            </div>
          </div>
          <br />
          <br />
        </div>
        <MeasurementsModal
          stationId={stationId}
          categoryId={props.station.category_id}
          show={handleFormMeasurements}
          setShow={setHandleFormMeasurements}
          changeMeasurements={(values) => changeMeasurement(values)}
          measurements={medicoes}
          dataEditForm={dataEditForm}
          setDataEditForm={setDataEditForm}
        ></MeasurementsModal>
      </Modal>

      <Portal>
        <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
          <Alert onClose={handleCloseSnackBar} variant="filled" severity={status} sx={{ width: '100%' }}>
            {
              status === "success" ? 
                <span>Operação realizada com sucesso!</span>
              : status === "error" ?
                <span>Erro!</span>
              : null
            }
          </Alert>
        </Snackbar>
      </Portal>

    </Grid>
  );
};

export default Measurements;
