import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography } from '@mui/material';
import SignUpForm from '../../components/SignUp/SignUpForm'

// hooks
import useResponsive from '../../hooks/useResponsive';
// components
// import Page from '../components/Page';
// import Logo from '../components/Logo';
// sections
// import AuthSocial from '../sections/auth/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(1, 16, 1, 16),
    padding: theme.spacing(4  , 4),
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(6),
  justifyContent: 'space-between',
  //margin: theme.spacing(0, 0, 0, 8),
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled('div')(({ theme }) => ({
  //width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',  
  padding: theme.spacing(8, 6),
}));

// ----------------------------------------------------------------------

export default function SignUpPage() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <div>
      <RootStyle>
          {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Quer conhecer o SmartSalt?    
            </Typography>
            <img src="/static/illustrations/signup.png" alt="login" />
          </SectionStyle>
        )}

          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              Cadastro no SmartSalt
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 2 }}>Insira seus dados abaixo.</Typography>

            { /* <AuthSocial /> */ }

            <SignUpForm />
          </ContentStyle>
      </RootStyle>
      </div>
  );
}
